// React core
import React from 'react';

// MUI components
import {
    Grid, Box, List, Avatar, Typography, IconButton, Divider,
    Stack, Alert, AlertTitle, Toolbar, CircularProgress, CssBaseline
} from '@mui/material';

// MUI icons
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RepartitionIcon from '@mui/icons-material/Repartition';

// Local components
import CustomListItem from './CustomListItem';
import Message from './Message';
import NavigationBar from '../Organisms/NavigationBar';

// Local utilities or additional components
import { QuestionSuggestions, MessageInputField } from './MessageInputField';


export function pcChatComponent(
    showArchivedChats,
    toggleAndFetchUnArchivedChats,
    toggleAndFetchArchivedChats,
    chatRooms,
    activeChat,
    setActiveChat,
    navigate,
    activeChatRoom,
    user,
    handleProfileLinkClick,
    markChatAsDeleted,
    messages,
    isLoadingAiResponse,
    scrollRef,
    sendMessage,
    userIcons
) {
    return (
        <>
            <CssBaseline />
            <NavigationBar />
            <Toolbar />
            <Box>
                <Grid container spacing={2}>
                    {/* 左側：チャットルーム一覧 */}
                    <Grid item xs={12} sm={3} style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}> {/* ヘッダーの高さを引いた高さで計算 */}
                        <Box sx={{ position: 'relative' }}>
                            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>チャットルーム</Typography>
                                {showArchivedChats ? (
                                    <IconButton aria-label="archive" onClick={toggleAndFetchUnArchivedChats} sx={{ position: 'absolute', right: 0 }}>
                                        <RepartitionIcon fontSize="large" />
                                    </IconButton>
                                ) : (
                                    <IconButton aria-label="archive" onClick={toggleAndFetchArchivedChats} sx={{ position: 'absolute', right: 0 }}>
                                        <ArchiveIcon fontSize="large" />
                                    </IconButton>
                                )}
                            </Box>
                            <List>
                                {chatRooms.map(room => (
                                    <CustomListItem
                                        key={room.id}
                                        avatarSrc={user.userType === 'company' ? room.companyDisplaySrc : room.avatarSrc}
                                        title={room.title}
                                        tag={room.chatTag}
                                        lastMessage={room.lastMessage || 'メッセージを開始してください'}
                                        isActive={activeChat === room.id}
                                        onClick={() => {
                                            setActiveChat(room.id);
                                            navigate(`/chat/${room.id}`);
                                        }} />
                                ))}
                            </List>
                        </Box>
                    </Grid>

                    {/* 右側：チャットルームの内容 */}

                    <Grid item xs={12} sm={9} style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}> {/* ヘッダーの高さを引いた高さで計算 */}
                        {activeChatRoom ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {/* <Avatar sx={{ mr: 3, width: 56, height: 56 }} aria-label="recipe" src={activeChatRoom.avatarSrc}> */}
                                        <Avatar sx={{ mr: 3, width: 56, height: 56 }} aria-label="recipe" src={user.userType === 'company' ? activeChatRoom.companyDisplaySrc : activeChatRoom.avatarSrc}>
                                            {activeChatRoom.title.charAt(0)}
                                            {/* <Avatar sx={{ mr: 3, width: 56, height: 56 }} aria-label="recipe" avatarSrc={user.userType === 'company' ? activeChatRoom.companyDisplaySrc : activeChatRoom.avatarSrc}> */}
                                            {/* {(activeChatRoom.avatarSrc || activeChatRoom.companyDisplaySrc) ? null : activeChatRoom.title.charAt(0)} */}
                                            {/* </Avatar> */}
                                        </Avatar>
                                        <Box>
                                            <Typography variant="subtitle2">チャットを開始しました</Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
                                                <Typography variant="h5" sx={{
                                                    fontWeight: 'bold',
                                                    mr: { sm: 3 }, // smサイズ以上でのみ右マージンを適用
                                                    mb: { xs: 1, sm: 0 }, // xsサイズで下マージンを適用、smサイズ以上では適用しない
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis'
                                                }}>
                                                    {activeChatRoom.title}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' }, flexDirection: { xs: 'column', sm: 'row' } }}>
                                                <Typography variant="subtitle1" color='text.secondary' sx={{ fontWeight: 'bold', mr: { xs: 0, sm: 3 } }}>
                                                    {activeChatRoom.chatTag}
                                                </Typography>
                                                {user.userType === 'company' && activeChatRoom.participants ? (
                                                    activeChatRoom.participants.filter(participant => participant.userType !== 'comany')
                                                        .map(participant => (
                                                            <Typography
                                                                key={participant.userId}
                                                                component="div"
                                                                variant="body2"
                                                                sx={{
                                                                    mr: { xs: 0, sm: 1 }, // smサイズ以上でのみ右マージンを適用
                                                                    display: 'flex',
                                                                    alignItems: { xs: 'flex-start', sm: 'center' },
                                                                    textDecoration: 'none',
                                                                    color: 'text.secondary',
                                                                    cursor: 'pointer',
                                                                    flexDirection: { xs: 'row', sm: 'row' }, // すべてのサイズでflexDirectionをrowに設定
                                                                    '&:hover': {
                                                                        color: 'primary.main', // ホバー時に色をprimaryのメインカラーに変更
                                                                    }
                                                                }}
                                                                onClick={() => handleProfileLinkClick(participant.userId)}
                                                            >
                                                                <AccountCircleIcon sx={{
                                                                    mr: { xs: 0, sm: 0.5 },
                                                                    fontSize: { xs: 'default', sm: 'default' } // 必要に応じてアイコンサイズを調整
                                                                }} />
                                                                プロフィールを見る
                                                            </Typography>
                                                        ))
                                                ) : (
                                                    <Typography
                                                        component="div"
                                                        variant="body2"
                                                        sx={{
                                                            mr: { xs: 0, sm: 1 }, // smサイズ以上でのみ右マージンを適用
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            textDecoration: 'none',
                                                            color: 'text.secondary',
                                                            cursor: 'pointer',
                                                            flexDirection: { xs: 'row', sm: 'row' }, // すべてのサイズでflexDirectionをrowに設定
                                                            '&:hover': {
                                                                color: 'primary.main', // ホバー時に色をprimaryのメインカラーに変更
                                                            }
                                                        }}
                                                        onClick={() => navigate(`/company/${activeChatRoom.companyId}/home`)}
                                                    >
                                                        <BusinessIcon sx={{
                                                            mr: { xs: 0, sm: 0.5 },
                                                            fontSize: { xs: 'default', sm: 'default' } // 必要に応じてアイコンサイズを調整
                                                        }} />
                                                        企業ページを見る
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {!showArchivedChats && (
                                        <>
                                            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                                <IconButton aria-label="delete" onClick={markChatAsDeleted} sx={{ mr: 2 }}>
                                                    <DeleteIcon fontSize="large" />
                                                </IconButton>
                                            </Box>

                                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                                <IconButton aria-label="delete" onClick={markChatAsDeleted}>
                                                    <DeleteIcon fontSize="large" />
                                                </IconButton>
                                            </Box>
                                        </>
                                    )}
                                </Box>

                                <Divider />

                                {/* チャットメッセージのリスト */}
                                <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
                                    <Stack spacing={2}>
                                        {messages.map((message) => (
                                            <Message key={message.id} message={message} user={user} userIcons={userIcons} />
                                        ))}
                                        {isLoadingAiResponse && (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                                <CircularProgress />
                                            </Box>
                                        )}
                                        {/* スクロールのためのダミー要素 */}
                                        <div ref={scrollRef} />
                                    </Stack>
                                </Box>

                                {/* 送信バー */}
                                {!showArchivedChats && messages.length === 0 && (
                                    <QuestionSuggestions onSend={sendMessage} />
                                )}
                                {!showArchivedChats ? (
                                    <Box sx={{ px: 1, pt: 0.5, display: 'flex', alignItems: 'center', mt: 'auto', width: '100%' }}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={true}>
                                                <MessageInputField onSend={sendMessage} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ) : (
                                    <Box sx={{ p: 1, display: 'flex', alignItems: 'center', mt: 'auto', width: '100%', justifyContent: 'center' }}>
                                        <Alert variant="filled" severity="error">
                                            <AlertTitle>アーカイブされたチャットルームです</AlertTitle>
                                        </Alert>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pb: 10 }}>
                                <Typography variant="h5">チャットルームを選択してください</Typography>
                            </Box>
                        )}

                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

// services/firebase/chatService.ts
import { collection, getDocs, query, where, addDoc, doc, serverTimestamp, orderBy, onSnapshot, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { ElasticFetchCompany, Company } from '../../types';

interface ChatData {
    participantIds: string[];
    participants: { userId: string; userType: string }[];
    companyId: string;
    companyRef: any;
    chatTag: string;
    avatarSrc?: string | null;
    companyDisplaySrc?: string | null;
    lastMessage: string;
    lastMessageTimestamp: any;
    archived: boolean;
    startedBy: { userId: string; userType: string };
    createdAt: any;
    updatedAt: any;
}

/**
 * ユーザーと企業ID、chatTagに基づいて既存のチャットを検索し、既存チャットがない場合は新しいチャットを作成します。
 * 
 * @async
 * @function startOrJoinChat
 * @param {Object} user - 現在のユーザーオブジェクト（uid、userTypeを含む）。
 * @param {string} companyId - チャットを関連付ける企業のID。
 * @param {string} chatTag - チャットのタグ。例: "AI担当者"。
 * @param {ElasticFetchCompany} company - 企業の情報を含むオブジェクト。
 * 
 * @returns {Promise<string>} - 既存のチャットがある場合はそのチャットIDを返し、なければ新しく作成されたチャットIDを返します。
 * 
 * @throws {Error} - Firebase Firestore操作が失敗した場合にエラーを投げます。
 * 
 * @example
 * const chatId = await startOrJoinChat(user, 'companyId123', 'AI担当者', company);
 * console.log(chatId); // チャットIDを出力
 */
export const startOrJoinChat = async (
    user: any, 
    companyId: string, 
    chatTag: string, 
    company: ElasticFetchCompany | Company
): Promise<string> => {
    const q = query(
        collection(firestore, 'chats'),
        where('participantIds', 'array-contains', user.uid),
        where('companyId', '==', companyId),
        where('chatTag', '==', chatTag),
        where('archived', '==', false)
    );

    const querySnapshot = await getDocs(q);

    // 既存チャットが存在する場合、そのチャットIDを返す
    if (!querySnapshot.empty) {
        const firstDoc = querySnapshot.docs[0];
        return firstDoc.id;
    } else {
        let participantIds: string[] = [];
        let participants: { userId: string; userType: string }[] = [];

        if (chatTag !== 'AI担当者') {
            const companyUsersQuery = query(
                collection(firestore, 'users'),
                where('userType', '==', 'company'),
                where('companyId', '==', companyId)
            );
            const usersSnapshot = await getDocs(companyUsersQuery);

            usersSnapshot.forEach((doc) => {
                participantIds.push(doc.id);
                participants.push({ userId: doc.id, userType: doc.data().userType });
            });
        }

        participantIds.push(user.uid);
        participants.push({ userId: user.uid, userType: user.userType });

        // 型ガード：ElasticFetchCompanyかCompanyかを確認
        const isElasticFetchCompany = (company: ElasticFetchCompany | Company): company is ElasticFetchCompany => {
            return (company as ElasticFetchCompany)._meta !== undefined;
        };

        const avatarSrc = isElasticFetchCompany(company)
            ? company?.iconurl?.raw ?? null  // ElasticFetchCompanyの場合
            : company?.iconUrl ?? null;      // Companyの場合

        const chatData: ChatData = {
            participantIds,
            participants,
            companyId: companyId,
            companyRef: doc(firestore, `companies/${companyId}`),
            chatTag,
            avatarSrc,
            companyDisplaySrc: user?.userIconUrl ?? null,
            lastMessage: '',
            lastMessageTimestamp: serverTimestamp(),
            archived: false,
            startedBy: { userId: user.uid, userType: user.userType },
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        };

        const docRef = await addDoc(collection(firestore, 'chats'), chatData);
        return docRef.id; // 新しいチャットのIDを返す
    }
};


// Function to fetch chat rooms based on user type
export const fetchChatRooms = (user: any, onRoomsFetched: (rooms: any[]) => void) => {
    let q;
    if (user.userType === 'company') {
        q = query(
            collection(firestore, 'chats'),
            where('companyId', '==', user.companyId),
            where('archived', '==', false),
            where('chatTag', '!=', 'AI担当者'),
            orderBy('updatedAt', 'desc')
        );
    } else {
        q = query(
            collection(firestore, 'chats'),
            where('participantIds', 'array-contains', user.uid),
            where('archived', '==', false),
            orderBy('updatedAt', 'desc')
        );
    }

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const rooms: any[] = [];
        for (const roomDoc of querySnapshot.docs) {
            const roomData = roomDoc.data();
            let title = '';
            let detail = '';

            if (user.userType !== 'company' && roomData.companyId) {
                const companyRef = doc(firestore, `companies/${roomData.companyId}`);
                const companySnap = await getDoc(companyRef);
                if (companySnap.exists()) {
                    title = companySnap.data().name;
                    detail = companySnap.data().companyDetail;
                }
            } else if (user.userType === 'company') {
                const participants = roomData.participants;
                const studentUserIds = participants
                    .filter((participant: { userType: string }) => participant.userType !== 'company')
                    .map((participant: { userId: string }) => participant.userId);

                const studentNames: string[] = [];
                for (const studentId of studentUserIds) {
                    const studentRef = doc(firestore, `users/${studentId}`);
                    const studentSnap = await getDoc(studentRef);
                    if (studentSnap.exists()) {
                        const studentData = studentSnap.data();
                        const studentName = `${studentData.lastName} ${studentData.firstName}`;
                        studentNames.push(studentName);
                    }
                }
                title = studentNames.join(', ');
            }

            rooms.push({
                id: roomDoc.id,
                ...roomData,
                title,
                detail,
            });
        }

        onRoomsFetched(rooms);
    });

    return unsubscribe;
};

// Function to add a new message to Firestore
export const addMessageToFirestore = async (chatId: string, messageText: string, userId: string) => {
    try {
        await addDoc(collection(firestore, `chats/${chatId}/messages`), {
            senderId: userId,
            text: messageText,
            timestamps: serverTimestamp(),
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        });
    } catch (error) {
        console.error('Error adding message to Firestore:', error);
    }
};
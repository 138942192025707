import { useEffect, useState } from 'react';
import { fetchCompanyInfo, updateCompanyInfo } from '../services/firebase/companyService';

export const useCompanyData = (companyId: string) => {
    const [companyInfo, setCompanyInfo] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const companyData = await fetchCompanyInfo(companyId);
                setCompanyInfo(companyData);
            } catch (err) {
                setError(err as Error);
            } finally {
                setIsLoading(false);
            }
        };

        if (companyId) {
            fetchData();
        }
    }, [companyId]);

    const updateCompany = async (updatedData: any) => {
        try {
            setIsLoading(true);
            await updateCompanyInfo(companyId, updatedData);
        } catch (err) {
            setError(err as Error);
        } finally {
            setIsLoading(false);
        }
    };

    return { companyInfo, isLoading, error, updateCompany };
};

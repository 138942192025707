import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Joyride, { CallBackProps } from 'react-joyride';
import {
    Box,
    CssBaseline,
    IconButton,
    List,
    useMediaQuery,
    Typography,
    Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArchiveIcon from '@mui/icons-material/Archive';
import RepartitionIcon from '@mui/icons-material/Repartition';

import CustomListItem from '../components/Chat/CustomListItem';
import { smChatComponent } from '../components/Chat/smChatComponent';
import { pcChatComponent } from '../components/Chat/pcChatComponent';
import FinishDialog from '../components/Tour/FinishDialog';
import NavigationBar from '../components/Organisms/NavigationBar';
import { SmChatAppBar } from '../components/Chat/SmChatAppBar';
import { useUser } from '../Context/UserContext';
import { useSnackbar } from '../Context/SnackbarContext';
import { TourContext, handleCallback } from '../Context/TourContext';
import { firestore } from '../firebase';
import {
    doc,
    getDoc,
    collection,
    query,
    where,
    onSnapshot,
    addDoc,
    serverTimestamp,
    orderBy,
    updateDoc
} from 'firebase/firestore';
import { GENERATE_AI_MESSAGE_FROM_INDEX } from './../utils/apiEndpoint';
import { mailTemplate } from '../utils/template';

interface ChatRoom {
    id: string;
    participants: Array<{ userType: string; userId: string }>;
    companyId?: string;
    title: string;
    detail: string;
    chatTag?: string;
    lastMessage?: string;
    avatarSrc?: string;
}

interface Message {
    id: string;
    senderId: string;
    text: string;
    timestamps: any;
    createdAt: any;
    updatedAt: any;
}

interface UserIcon {
    uid: string;
    userIconUrl: string;
}

const Chat: React.FC = () => {
    const [activeChat, setActiveChat] = useState<string | null>(null);
    const [chatRooms, setChatRooms] = useState<ChatRoom[]>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [isLoadingAiResponse, setIsLoadingAiResponse] = useState<boolean>(false);
    const [showArchivedChats, setShowArchivedChats] = useState<boolean>(false);
    const [userIcons, setUserIcons] = useState<UserIcon[]>([]);
    const [openFinishTourDialog, setOpenFinishTourDialog] = useState<boolean>(false);

    const scrollRef = useRef<HTMLDivElement>(null);
    const { showSnackbar } = useSnackbar();
    const { steps, setSteps, run, setRun } = useContext(TourContext);
    const { user } = useUser();
    const navigate = useNavigate();
    const { id: chatId } = useParams<{ id: string }>();

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const activeChatRoom = chatRooms.find(room => room.id === activeChat);

    const handleProfileLinkClick = (participantId: string) => {
        navigate(`/profile/${participantId}`);
    };

    useEffect(() => {
        setActiveChat(chatId || null);
    }, [chatId]);

    useEffect(() => {
        if (!user) return;

        let q;
        if (user.userType === 'company') {
            q = query(
                collection(firestore, 'chats'),
                where('companyId', '==', user.companyId),
                where('archived', '==', false),
                where('chatTag', '!=', 'AI担当者'),
                orderBy('updatedAt', 'desc')
            );
        } else {
            q = query(
                collection(firestore, 'chats'),
                where('participantIds', 'array-contains', user.uid),
                where('archived', '==', false),
                orderBy('updatedAt', 'desc')
            );
        }

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const rooms: ChatRoom[] = [];
            for (const roomDoc of querySnapshot.docs) {
                const roomData = roomDoc.data();
                let title = '';
                let detail = '';

                if (user.userType !== 'company' && roomData.companyId) {
                    const companyRef = doc(firestore, `companies/${roomData.companyId}`);
                    const companySnap = await getDoc(companyRef);
                    if (companySnap.exists()) {
                        title = companySnap.data().name;
                        detail = companySnap.data().companyDetail;
                    }
                } else if (user.userType === 'company') {
                    const participants = roomData.participants;
                    const studentUserIds = participants.filter((participant: { userType: string; }) => participant.userType !== 'company').map((participant: { userId: any; }) => participant.userId);

                    const studentNames: string[] = [];
                    for (const studentId of studentUserIds) {
                        const studentRef = doc(firestore, `users/${studentId}`);
                        const studentSnap = await getDoc(studentRef);
                        if (studentSnap.exists()) {
                            const studentData = studentSnap.data();
                            const studentName = `${studentData.lastName} ${studentData.firstName}`;
                            studentNames.push(studentName);
                        }
                    }
                    title = studentNames.join(', ');
                    detail = '';
                }

                rooms.push({
                    id: roomDoc.id,
                    ...roomData,
                    title,
                    detail,
                    participants: []
                });
            }

            setChatRooms(rooms);
        });

        return () => unsubscribe();
    }, [user]);

    useEffect(() => {
        if (!activeChat) return;
        const q = query(collection(firestore, `chats/${activeChat}/messages`), orderBy('timestamps'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const msgs = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            } as Message));
            setMessages(msgs);
        });

        const fetchUserIcons = async () => {
            const chatDocRef = doc(firestore, 'chats', activeChat);
            const chatDoc = await getDoc(chatDocRef);

            if (chatDoc.exists()) {
                const participantIds = chatDoc.data().participantIds;
                const userIconPromises = participantIds.map(async (uid: string) => {
                    const userDocRef = doc(firestore, 'users', uid);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        return { uid, userIconUrl: userDoc.data().userIconUrl || 'null' };
                    }
                    return { uid, userIconUrl: 'default-icon-url.png' };
                });
                const icons = await Promise.all(userIconPromises);
                setUserIcons(icons);
            }
        };
        fetchUserIcons();

        return () => unsubscribe();
    }, [activeChat]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        if (user) {
            setRun(user.tutorialStatus === 'completed' || user.tutorialStatus === 'skipped' ? false : true);
        } else {
            setRun(true);
        }
    }, [setRun, user]);

    useEffect(() => {
        if (steps.length === 0) {
            setRun(false);
            return;
        }

        const newSteps = [...steps];
        const lastStepIndex = newSteps.length - 1;

        const newData = {
            messageText: "どんな会社ですか？",
            user,
            activeChat,
            activeChatRoom,
            setIsLoadingAiResponse,
            setRun,
            setOpenFinishTourDialog,
        };

        if (newSteps[lastStepIndex] && JSON.stringify(newSteps[lastStepIndex].data) !== JSON.stringify(newData)) {
            newSteps[lastStepIndex].data = newData;
            setSteps(newSteps);
        }
    }, [setSteps, activeChatRoom, user, activeChat, steps, setIsLoadingAiResponse, setOpenFinishTourDialog, setRun]);

    const addMessageToFirestore = async (chatId: string, messageText: string, userId: string) => {
        try {
            await addDoc(collection(firestore, `chats/${chatId}/messages`), {
                senderId: userId,
                text: messageText,
                timestamps: serverTimestamp(),
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
            });
        } catch (error) {
            console.error('Error adding message to Firestore:', error);
        }
    };

    const callAiMessageEndpoint = async (text: string, companyId: string, companyName: string) => {
        const response = await fetch(GENERATE_AI_MESSAGE_FROM_INDEX, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                text,
                company_id: companyId,
                company_name: companyName,
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    };

    const formatSimilarityChunksInfoData = (data: any) => {
        return data.map((item: any) => ({
            score: item[1][0],
            filename: item[0],
        }));
    };

    const sendMessage = async (messageText: string) => {
        if (!activeChatRoom) return; // Ensure activeChatRoom is defined

        await addMessageToFirestore(activeChat!, messageText, user!.uid);

        if (activeChatRoom.chatTag === 'AI担当者') {
            setIsLoadingAiResponse(true);
            try {
                const aiResponse = await callAiMessageEndpoint(
                    messageText,
                    activeChatRoom.companyId!,
                    activeChatRoom.title
                );
                if (aiResponse?.response?.choices?.length > 0) {
                    const aiMessageContent = aiResponse.response.choices[0].message.content;
                    await addDoc(collection(firestore, `chats/${activeChat}/messages`), {
                        senderId: 'AI',
                        text: aiMessageContent,
                        similar_chunks_info: formatSimilarityChunksInfoData(aiResponse?.similar_chunks_info) || null,
                        usage_completion_tokens: aiResponse?.response.usage.completion_tokens || null,
                        usage_prompt_tokens: aiResponse?.response.usage.prompt_tokens || null,
                        usage_total_tokens: aiResponse?.response.usage.total_tokens || null,
                        timestamps: serverTimestamp(),
                        createdAt: serverTimestamp(),
                        updatedAt: serverTimestamp(),
                    });
                }
            } catch (error) {
                console.error('AI message retrieval error:', error);
            } finally {
                setIsLoadingAiResponse(false);
            }
        }

        if (activeChatRoom.chatTag !== 'AI担当者') {
            try {
                // Use participants and extract userId
                const otherParticipantIds = activeChatRoom.participants
                    .filter(participant => participant.userId !== user?.uid)
                    .map(participant => participant.userId);

                const notificationUids: string[] = [];
                for (const participantId of otherParticipantIds) {
                    const userRef = doc(firestore, 'users', participantId);
                    const userSnap = await getDoc(userRef);

                    if (userSnap.exists() && userSnap.data().emailNotification === 'notify') {
                        notificationUids.push(participantId);
                    }
                }

                if (notificationUids.length > 0) {
                    await addDoc(collection(firestore, 'mail'), {
                        toUids: notificationUids,
                        message: {
                            subject: '【Ciel】メッセージが届いています',
                            html: mailTemplate({ activeChatRoomId: activeChatRoom.id }), // Pass the correct object
                        },
                    });
                }
            } catch (error) {
                console.error('Error adding mail to Firestore:', error);
            }
        }
    };

    const markChatAsDeleted = async () => {
        const chatRef = doc(firestore, 'chats', activeChat!);
        try {
            await updateDoc(chatRef, {
                archived: true,
            });
            navigate('/chat/null');
            showSnackbar({ message: 'チャットをアーカイブしました', type: 'success', title: '削除完了' });
        } catch (error) {
            showSnackbar({ message: '再度お試しください', type: 'error', title: '削除失敗' });
        }
    };

    const toggleAndFetchArchivedChats = async () => {
        let q;
        if (user?.userType !== 'company') {
            q = query(
                collection(firestore, 'chats'),
                where('participantIds', 'array-contains', user?.uid),
                where('archived', '==', true)
            );
        } else {
            q = query(
                collection(firestore, 'chats'),
                where('companyId', '==', user?.companyId),
                where('archived', '==', true)
            );
        }

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const rooms: ChatRoom[] = [];
            for (const roomDoc of querySnapshot.docs) {
                const roomData = roomDoc.data();
                let title = '';
                let detail = '';

                if (user?.userType !== 'company' && roomData.companyId) {
                    const companyRef = doc(firestore, `companies/${roomData.companyId}`);
                    const companySnap = await getDoc(companyRef);
                    if (companySnap.exists()) {
                        title = companySnap.data().name;
                        detail = companySnap.data().companyDetail;
                    }
                } else if (user?.userType === 'company') {
                    title = `${roomData.startedUserFirstName} ${roomData.startedUserLastName}`;
                    detail = '';
                }

                rooms.push({
                    id: roomDoc.id,
                    ...roomData,
                    title,
                    detail,
                    participants: []
                });
            }

            setChatRooms(rooms);
            setShowArchivedChats(true);
        });

        return () => unsubscribe();
    };

    const toggleAndFetchUnArchivedChats = async () => {
        let q;
        if (user?.userType !== 'company') {
            q = query(
                collection(firestore, 'chats'),
                where('participantIds', 'array-contains', user?.uid),
                where('archived', '==', false)
            );
        } else {
            q = query(
                collection(firestore, 'chats'),
                where('companyId', '==', user?.companyId),
                where('archived', '==', false)
            );
        }

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const rooms: ChatRoom[] = [];
            for (const roomDoc of querySnapshot.docs) {
                const roomData = roomDoc.data();
                let title = '';
                let detail = '';

                if (user?.userType !== 'company' && roomData.companyId) {
                    const companyRef = doc(firestore, `companies/${roomData.companyId}`);
                    const companySnap = await getDoc(companyRef);
                    if (companySnap.exists()) {
                        title = companySnap.data().name;
                        detail = companySnap.data().companyDetail;
                    }
                } else if (user?.userType === 'company') {
                    title = `${roomData.startedUserFirstName} ${roomData.startedUserLastName}`;
                    detail = '';
                }

                rooms.push({
                    id: roomDoc.id,
                    ...roomData,
                    title,
                    detail,
                    participants: []
                });
            }

            setChatRooms(rooms);
            setShowArchivedChats(false);
        });

        return () => unsubscribe();
    };

    return (
        <>
            {user && (
                <Joyride
                    callback={handleCallback as (data: CallBackProps) => void}
                    continuous={true}
                    run={run}
                    steps={steps}
                    stepIndex={2}
                    scrollToFirstStep={true}
                    showProgress={true}
                    showSkipButton={true}
                    locale={{
                        back: '戻る',
                        close: '閉じる',
                        last: '質問する',
                        next: '次へ',
                        skip: 'スキップ',
                    }}
                    styles={{
                        options: {
                            zIndex: 1000,
                        },
                        buttonNext: {
                            backgroundColor: '#1976d2',
                            color: 'white',
                            borderRadius: '4px',
                            padding: '12px 16px',
                            textTransform: 'uppercase',
                            minWidth: '64px',
                            border: 'none',
                            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                        },
                        buttonBack: {
                            backgroundColor: '#eeeeee',
                            color: '#616161',
                            borderRadius: '4px',
                            padding: '12px 16px',
                            textTransform: 'uppercase',
                            minWidth: '64px',
                        },
                    }}
                />
            )}

            <FinishDialog open={openFinishTourDialog} onClose={() => setOpenFinishTourDialog(false)} />

            {isSm ? (
                pcChatComponent(
                    showArchivedChats,
                    toggleAndFetchUnArchivedChats,
                    toggleAndFetchArchivedChats,
                    chatRooms,
                    activeChat,
                    setActiveChat,
                    navigate,
                    activeChatRoom,
                    user,
                    handleProfileLinkClick,
                    markChatAsDeleted,
                    messages,
                    isLoadingAiResponse,
                    scrollRef,
                    sendMessage,
                    userIcons
                )
            ) : chatId === 'null' ? (
                <>
                    <NavigationBar />
                    <Box sx={{ mt: 10, mb: 0 }}>
                        <Box sx={{ position: 'relative' }}>
                            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                    チャットルーム
                                </Typography>
                                {showArchivedChats ? (
                                    <IconButton aria-label="archive" onClick={toggleAndFetchUnArchivedChats} sx={{ position: 'absolute', right: 0 }}>
                                        <RepartitionIcon fontSize="large" />
                                    </IconButton>
                                ) : (
                                    <IconButton aria-label="archive" onClick={toggleAndFetchArchivedChats} sx={{ position: 'absolute', right: 0 }}>
                                        <ArchiveIcon fontSize="large" />
                                    </IconButton>
                                )}
                            </Box>
                            <List>
                                {chatRooms.map((room) => (
                                    <CustomListItem
                                        key={room.id}
                                        avatarSrc={room.avatarSrc}
                                        title={room.title}
                                        tag={room?.chatTag}
                                        lastMessage={room.lastMessage || 'メッセージを開始してください'}
                                        isActive={activeChat === room.id}
                                        onClick={() => {
                                            setActiveChat(room.id);
                                            navigate(`/chat/${room.id}`);
                                        }}
                                    />
                                ))}
                            </List>
                        </Box>
                    </Box>
                </>
            ) : (
                <>
                    <CssBaseline />
                    <SmChatAppBar activeChatRoom={activeChatRoom} />
                    <Toolbar id="back-to-top-anchor" />
                    {smChatComponent(messages, user!, isLoadingAiResponse, scrollRef, showArchivedChats, sendMessage, markChatAsDeleted, userIcons)}
                </>
            )}
        </>
    );
};

export default Chat;

import React, { useContext, useState, useEffect, useCallback } from 'react';
import Joyride from 'react-joyride';

import {
    Button,
    Divider,
    Typography,
    Grid,
    Container,
    Pagination,
    Box,
    CircularProgress,
    Fab
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useUser } from '../Context/UserContext.js';
import { TourContext, handleCallback } from '../Context/TourContext.js';

import { useFetchCompanies } from '../hooks/useFetchCompanies';
import { skipTutorial } from '../services/firebase/firestore';

import NavigationBar from '../components/Organisms/NavigationBar';
import CompanySearchBar from '../components/Organisms/CompanySearchBar';
import Footer from '../components/Organisms/Footer';
import CompanyCard from '../components/Templates/CompanyCard';
import ResponsiveContainer from '../components/Templates/ResponsiveContainer';

import GifteeCampaignDialog from '../components/Input/GifteeCampaignDialog.js';

import { CompanySearchParams, CompanySearchFeature, ElasticFetchCompany } from '../types';

/**
 * 会社一覧ページのコンポーネント
 * @returns {JSX.Element} 会社一覧ページのコンポーネント
 * 
 * @example
 * <CompanyList />
 * 
 * @note 会社一覧ページには、検索バー、会社カード、ページネーションが含まれます。
 * @note shouldFetch stateを削除すると非同期で値が変更されるたびに検索が実行されるようになります。
 */
const CompanyList: React.FC = () => {
    const [page, setPage] = useState<number>(1);
    const [searchParams, setSearchParams] = useState<CompanySearchParams>({
        searchText: '',
        jobType: '',
        category: '',
    });
    const [features, setFeatures] = useState<CompanySearchFeature[]>([
        { name: "AIの解答が正確", enabled: false },
        { name: "担当者と話せる", enabled: false },
        { name: "ブックマーク済み", enabled: false },
    ]);
    const [eventDialogOpen, setEventDialogOpen] = useState<boolean>(false);
    const [companies, setCompanies] = useState<ElasticFetchCompany[]>([]);
    const [totalCompanies, setTotalCompanies] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const { steps, setSteps, run, setRun } = useContext(TourContext);

    const { user } = useUser();
    const theme = useTheme();
    const itemsPerPage = 10;

    // const { companies, totalCompanies, totalPages, loading } = useFetchCompanies(features, user, searchParams, itemsPerPage, page, shouldFetch);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const results = await useFetchCompanies(features, user, searchParams, itemsPerPage, page);
                setCompanies(results.companies);
                setTotalCompanies(results.totalCompanies);
                setTotalPages(results.totalPages);
            } catch (error) {
                console.error("Failed to fetch companies", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData(); // APIを呼び出す
    }, []);

    useEffect(() => {
        setSteps([
            {
                content: (
                    <>
                        <h3>Cielへようこそ！<br />チュートリアルをはじめよう</h3>
                        <Button
                            color="secondary"
                            onClick={() => skipTutorial(user.uid)}
                            style={{ marginTop: '20px' }}
                        >
                            スキップ
                        </Button>
                    </>
                ),
                locale: { skip: <strong aria-label="skip">Ciel Inc.</strong> },
                placement: 'center',
                target: 'body',
            }
        ]);
        if (user) {
            if (user?.tutorialStatus === 'completed' || user?.tutorialStatus === 'skipped') {
                setRun(false);
            } else {
                setRun(true);
            }
        } else {
            setRun(true);
        }
    }, [user, setSteps, setRun]);

    /**
     * 検索を実行する際に呼ばれる関数
     * ページをリセットし、フィルタリングに基づいて新しい結果をフェッチします。
     */
    const handleSearch = useCallback(() => {
        setPage(1);
        const fetchData = async () => {
            setLoading(true);
            try {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const results = await useFetchCompanies(features, user, searchParams, itemsPerPage, page);
                setCompanies(results.companies);
                setTotalCompanies(results.totalCompanies);
                setTotalPages(results.totalPages);
            } catch (error) {
                console.error("Failed to fetch companies", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData(); // APIを呼び出す
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, features]);

    /**
     * ページ変更時に呼ばれる関数
     * @param {any} _ - イベントの情報 (未使用)
     * @param {number} value - 新しいページ番号
     */
    const handlePageChange = (_: any, value: number) => {
        setPage(value);
        const fetchData = async () => {
            setLoading(true);
            try {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const results = await useFetchCompanies(features, user, searchParams, itemsPerPage, page);
                setCompanies(results.companies);
                setTotalCompanies(results.totalCompanies);
                setTotalPages(results.totalPages);
            } catch (error) {
                console.error("Failed to fetch companies", error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchData(); // APIを呼び出す
        window.scrollTo(0, 0);
    };

    /**
     * 検索バーの入力変更時に呼ばれる関数
     * @param {React.ChangeEvent<HTMLInputElement>} event - 入力フィールドの変更イベント
     */
    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSearchParams(prev => ({ ...prev, [name]: value }));
    }, []);

    /**
     * 機能フィルタのトグルを切り替える関数
     * @param {string} featureName - トグルする機能の名前
     */
    const toggleFeature = useCallback((featureName: string) => {
        setFeatures(currentFeatures =>
            currentFeatures.map(feature =>
                feature.name === featureName ? { ...feature, enabled: !feature.enabled } : feature
            )
        );
    }, []);

    return (
        <>
            {user && (
                <Joyride
                    callback={handleCallback}
                    run={run}
                    continuous={true}
                    steps={steps}
                    scrollToFirstStep={true}
                    showProgress={true}
                    showSkipButton={false}
                    locale={{
                        back: '戻る',
                        close: '閉じる',
                        last: '質問する',
                        next: '次へ',
                        skip: 'スキップ'
                    }}
                    styles={{
                        options: { zIndex: 1000 },
                        buttonNext: {
                            backgroundColor: '#1976d2', // Material-UI primary color
                            color: 'white',
                            borderRadius: '4px',
                            padding: '12px 16px',
                            textTransform: 'uppercase',
                            minWidth: '64px',
                            border: 'none',
                            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)', // Material-UI shadow
                        },
                        buttonBack: {
                            backgroundColor: '#eeeeee', // Material-UI grey color
                            color: '#616161',
                            borderRadius: '4px',
                            padding: '12px 16px',
                            textTransform: 'uppercase',
                            minWidth: '64px',
                        },
                    }}
                />
            )}
            {!user?.isCampaignParticipant && user?.userType !== 'company' && user?.userType !== 'admin' && (
                <Fab variant="extended" sx={{
                    position: 'fixed',
                    right: theme.spacing(0),
                    bottom: theme.spacing(5),
                    borderRadius: '8px 0px 0px 8px',
                    width: '200px',
                    height: '100px',
                    zIndex: 10
                }} color="primary" onClick={() => setEventDialogOpen(true)}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>条件を達成して<br />giftee Boxをもらおう</Typography>
                </Fab >
            )}
            <GifteeCampaignDialog open={eventDialogOpen} onClose={() => setEventDialogOpen(false)} onOpen={undefined} />

            <NavigationBar />
            <ResponsiveContainer>
                <Grid container spacing={1.5}>
                    <Grid item xs={12} md={3.5}>
                        <Container sx={{ px: { xs: 1, sm: 2, md: 3 } }}>
                            <CompanySearchBar
                                searchParams={searchParams}
                                handleInputChange={handleInputChange}
                                handleSearch={handleSearch}
                                toggleFeature={toggleFeature}
                                features={features}
                            />
                        </Container>
                    </Grid>
                    <Grid item xs={12} md={8.5}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: 640, width: '100%' }}>
                            <Container maxWidth="lg" sx={{ px: { xs: 1, sm: 2, md: 3 } }}>
                                <Box sx={{ display: "flex", justifyContent: 'space-between' }} className='home'>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography variant="subtitle2" component="h6" color="text.secondary" sx={{ display: 'flex', alignItems: 'flex-end', pb: 1 }}>{itemsPerPage}/</Typography>
                                        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'flex-end', pb: 1, fontWeight: 'bold' }}>{totalCompanies}</Typography>
                                    </Box>
                                </Box>
                                <Divider sx={{ mb: 4 }} />
                                <Grid container spacing={4} justifyContent="center">
                                    {loading ? (
                                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                                            <CircularProgress />
                                        </Box>
                                    ) : companies.length > 0 ? (
                                        companies.map((company: ElasticFetchCompany, index: number) => (
                                            <Grid item key={company?.id?.raw} xs={12}>
                                                <CompanyCard company={company} isGuide={index === 0} />
                                                {index !== companies.length - 1 && <Divider />}
                                            </Grid>
                                        ))
                                    ) : (
                                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                                            <Typography sx={{ textAlign: 'center' }}>検索結果が見つかりませんでした<br />条件を変更して再度お試しください。</Typography>
                                        </Box>
                                    )}
                                </Grid>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                                    <Pagination
                                        count={totalPages}
                                        page={page}
                                        onChange={handlePageChange}
                                        color="primary"
                                    />
                                </Box>
                            </Container>
                        </Box>
                    </Grid>
                </Grid>
            </ResponsiveContainer>
            <Footer />
        </>
    );
}

export default CompanyList;

import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableRow, Collapse, IconButton, Box, Typography, Stack, Button, TableHead } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { firestore } from '../../../../firebase';
import { doc, updateDoc, collection, getDocs } from 'firebase/firestore';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { useSnackbar } from '../../../../Context/SnackbarContext';

import { getRoleDescription } from '../../../../utils/helpers/getRoleDescription';

import { useCallApi } from '../../../../hooks/useCallApi';
import AuthIdDialog from '../../../../components/Company/AuthIdDialog';

import { getImageUrl } from '../../../../services/firebase/getImageUrl';
import { CREATE_COMPANY_PROFILE_WITH_IMAGE } from '../../../../utils/apiEndpoint';

import axios from 'axios';

// TableRow内のサムネイル画像表示用コンポーネント
const ThumbnailImage = ({ rowId }) => {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        if (!rowId) return;

        const imagePath = `company_images/${rowId}/thumbnail_200x200.webp`;

        const fetchImageUrl = async () => {
            const url = await getImageUrl(imagePath);
            setImageUrl(url);
        };

        fetchImageUrl();
    }, [rowId]);

    return imageUrl ? (
        <img src={imageUrl} alt="サムネイル" style={{
            width: '160px',
            height: '90px',
            objectFit: 'cover',
            borderRadius: '4px',
        }} />
    ) : (
        "画像なし"
    );
};

// 企業情報を更新する関数
const postUpdateCompanyInfo = async (companyId, companyName, stripeCustomerId) => {
    // const url = 'http://127.0.0.1:5001/ciel-dac23/asia-northeast1/create_update_stripe_user';
    const url = 'https://create-update-stripe-user-frnrslgk3q-an.a.run.app';

    // POSTリクエストのデータ
    const data = {
        companyName: companyName,
        companyId: companyId,
        stripeCustomerId: stripeCustomerId
    };

    try {
        const response = await axios.post(url, data);
        return response.status;  // ステータスコードを返す
    } catch (error) {
        if (error.response) {
            return error.response.status;  // エラーレスポンスのステータスコードを返す
        }
        return null;  // レスポンスがない場合はnullを返す
    }
};

const Row = ({ row }) => {
    const { showSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [authIds, setAuthIds] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    const callApi = useCallApi();

    useEffect(() => {
        const loadAuthIds = async () => {
            const companyRef = doc(firestore, 'companies', row.id);
            const authIdsCollectionRef = collection(companyRef, 'authIds');
            const snapshot = await getDocs(authIdsCollectionRef);
            const authIdsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAuthIds(authIdsList);
        };

        loadAuthIds();
    }, [row.id]);

    // 日付をフォーマットする関数
    const formatDate = (date) => {
        return date ? new Date(date.seconds * 1000).toISOString().split('T')[0] : 'N/A';
    };

    const handleDelete = (id) => {
        // Assuming you have access to the firestore instance
        const docRef = doc(firestore, 'companies', id);

        // Update the document with the isDeleted flag set to true
        updateDoc(docRef, {
            isDeleted: true
        })
            .then(() => {
                showSnackbar({ message: "削除しました", type: 'success' });
            })
            .catch((error) => {
                showSnackbar({ message: "エラーが発生しました", type: 'error' });
            });
    };

    // 画像の作成関数
    const createImageAndProfile = async () => {
        // const apiUrl = 'http://127.0.0.1:5001/ciel-dac23/asia-northeast1/create_company_profile_with_image';
        const apiUrl = CREATE_COMPANY_PROFILE_WITH_IMAGE;
        const result = await callApi(
            apiUrl,
            { id: row.id, name: row.name },
            'POST',
        );
        if (result.success) {
            // 成功時の処理
            showSnackbar({ message: "データの移動が完了しました", type: 'success' });
        } else {
            // エラー時の処理
            showSnackbar({ message: `エラーが発生しました: ${result.error.message}`, type: 'error' });
        }
    };

    const updateCompanyInfo = async (companyId, companyName, stripeCustomerId, showSnackbar) => {
        try {
            const stripeResponse = await postUpdateCompanyInfo(
                companyId,
                companyName,
                stripeCustomerId
            );

            if (stripeResponse.status !== 200) {
                console.error("Stripeへのリクエストに失敗しました", stripeResponse);
                return;
            } else {
                showSnackbar({ message: "stripeへ追加しました", type: 'success' });
            }
            showSnackbar({ message: "stripeへ追加しました", type: 'success' });
        } catch (error) {
            console.error("会社情報の更新に失敗しました", error);
        }
    };


    return (
        <>
            <AuthIdDialog
                companyId={row.id}
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            />

            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    <ThumbnailImage rowId={row.id} />
                </TableCell>
                <TableCell component="th" scope="row">{row.name}</TableCell>
                <TableCell>{row.websiteUrl}</TableCell>
                <TableCell align="right">{row.numChunks}</TableCell>
                <TableCell align="right">{formatDate(row.createdAt)}</TableCell>
                <TableCell align="center">
                    <IconButton color="primary" onClick={() => console.log('edit')}>
                        <EditIcon />
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    <IconButton color="primary" onClick={() => setOpenDialog(true)}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    <IconButton color="error" onClick={() => handleDelete(row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">詳細情報</Typography>
                            <Table size="small" aria-label="details">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">企業名</TableCell>
                                        <TableCell align="right">{row?.name || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* カテゴリ */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">カテゴリ</TableCell>
                                        <TableCell align="right">{row?.categories?.join(', ') || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* サムネイルURL */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">サムネイルURL</TableCell>
                                        <TableCell align="right">{row?.thumbnailUrl || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* アイコンURL */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">アイコンURL</TableCell>
                                        <TableCell align="right">{row?.iconUrl || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* ウェブサイトURL */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">ウェブサイトURL</TableCell>
                                        <TableCell align="right">{row?.websiteUrl || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 企業ID */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">企業ID</TableCell>
                                        <TableCell align="right">{row?.id || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 従業員数 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">従業員数</TableCell>
                                        <TableCell align="right">{row?.employeeCount || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 住所 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">住所</TableCell>
                                        <TableCell align="right">{row?.address || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 事業概要 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">事業概要</TableCell>
                                        <TableCell align="right">{row?.bussinessOutline || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 訪問者数 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">訪問者数</TableCell>
                                        <TableCell align="right">{row?.viewCount || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 代表者名 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">代表者名</TableCell>
                                        <TableCell align="right">{row?.leaderName || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* キャッチコピー */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">キャッチコピー</TableCell>
                                        <TableCell align="right">{row?.catchphrase || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* タグ */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">タグ</TableCell>
                                        <TableCell align="right">{row?.tags?.join(', ') || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 追加ステータス */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">追加状況</TableCell>
                                        <TableCell align="right">{row?.status || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* Chunk数 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">Chunk数</TableCell>
                                        <TableCell align="right">{row?.numChunks || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 作成日 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">作成日</TableCell>
                                        <TableCell align="right">{formatDate(row?.createdAt) || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 更新日 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">更新日</TableCell>
                                        <TableCell align="right">{formatDate(row?.updatedAt) || 'N/A'}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Stack spacing={2} sx={{ marginTop: 2, marginBottom: 2, justifyContent: 'flex-end' }}>
                                <Stack direction="row" spacing={2} sx={{ marginTop: 2, marginBottom: 2, justifyContent: 'flex-end' }}>
                                    {/* 既存のボタン */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={createImageAndProfile}
                                        disabled={row.thumbnailUrl} // 条件に応じてボタンを無効化
                                    >
                                        画像作成
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => updateCompanyInfo(row.id, row.name, row?.stripeCustomerId)}
                                        disabled={row?.stripeCustomerId} // 条件に応じてボタンを無効化
                                    >
                                        Stripeに登録
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>

                        {/* <Typography variant="h6">認証IDリスト</Typography> */}
                        <Box sx={{ margin: 1, pb: 3 }}>
                            <Typography variant="h6" gutterBottom component="div">認証IDリスト</Typography>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell align="right">Role</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {authIds.map((authId) => (
                                        <TableRow key={authId.id}>
                                            <TableCell component="th" scope="row">
                                                {authId.authId}
                                            </TableCell>
                                            <TableCell align="right">
                                                {getRoleDescription(authId.role)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default Row;
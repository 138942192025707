// 1. React and third-party library imports
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

// 2. Local utilities and hooks
import useFetchImageUrl from '../../hooks/useFetchImageUrl';
import { useSnackbar } from '../../Context/SnackbarContext';
import { useUser } from '../../Context/UserContext';
import { TourContext } from '../../Context/TourContext';

// 3. Services
import { incrementViewCount } from '../../services/firebase/companyService'; // 会社のビューカウント増加サービス
import { startOrJoinChat } from '../../services/firebase/chatService'; // チャットのサービス

// 4. Custom components
import BookmarkButton from '../Common/BookmarkButton';
import RegistrationDialog from '../Organisms/RegistrationDialog';

// 5. Types
import { ElasticFetchCompany } from '../../types';


interface CompanyCardProps {
    company: ElasticFetchCompany;
    isGuide?: boolean;
}

// `steps` と `newSteps` の型に `{ target: string }` を仮定
interface Step {
    target: string;
}

// 企業カードコンポーネント
const CompanyCard: React.FC<CompanyCardProps> = ({ company, isGuide = false }) => {
    // ホバー状態を管理するための状態
    const [isHovered, setIsHovered] = useState(false);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [showRegistrationDialog, setShowRegistrationDialog] = useState(false);

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { user } = useUser();
    const { showSnackbar } = useSnackbar();
    const { steps, setSteps } = useContext(TourContext);

    const itemId = company?.id?.raw
    const itemTags = company?.tags?.raw
    const { imageUrl, loading } = useFetchImageUrl(itemId, isMobile);

    // joyrideステップを追加
    useEffect(() => {
        if (!isGuide) return;
        // 追加するステップ
        const newSteps = [
            {
                target: '.talk-to-ai-btn',
                content: <p>企業ごとのAIと話すことができます。<br />AI担当者とのチャットを開始しましょう！</p>,
                floaterProps: {
                    offset: 20, // オフセットを適切に設定
                    disableAnimation: true,
                },
                placement: 'top',
                spotlightPadding: 20,
                data: {
                    chatTag: 'AI担当者',
                    companyId: itemId,
                    companyInfo: company,
                    user: user,
                    navigate: navigate,
                },
            },
            {
                target: '.first-question-btn',
                content: '初めての質問をしてみよう',
                floaterProps: {
                    offset: 20, // オフセットを適切に設定
                    disableAnimation: true,
                },
                placement: 'top',
                spotlightPadding: 20,
                data: {},
            }
        ];
        // 新しいステップが既に存在しない場合のみ追加する
        const isStepExist = steps.some((step: Step) => newSteps.some((newStep: Step) => newStep.target === step.target));

        if (!isStepExist) {
            setSteps([...steps, ...newSteps]);
        }
    }, [isGuide, itemId, company, user, navigate, steps, setSteps]);

    // 初期状態でブックマークされているかどうかを確認
    useEffect(() => {
        setIsBookmarked(user?.companyBookMarks?.includes(itemId));
    }, [user, itemId]);

    const toggleBookmark = () => {
        setIsBookmarked(!isBookmarked);
    };

    const handleChat = async () => {
        if (!user) {
            setShowRegistrationDialog(true);
            return;
        }

        try {
            const chatId = await startOrJoinChat(user, itemId, 'AI担当者', company);
            navigate(`/chat/${chatId}`);
        } catch (error) {
            showSnackbar({ message: 'チャットの開始に失敗しました。', type: 'error' });
        }
    };

    return (
        <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{ cursor: 'pointer' }} // カーソルをポインターに設定してユーザーにインタラクティブであることを示す
        >
            <RegistrationDialog
                open={showRegistrationDialog}
                onClose={() => setShowRegistrationDialog(false)}
                afterAction={undefined}
            />
            <Box component={Link}
                onClick={(e) => {
                    e.preventDefault(); // デフォルトのリンクの挙動を防ぐ
                    incrementViewCount(itemId).then(() => {
                        // データ更新後にページ遷移
                        navigate(`/company/${itemId}/home`);
                    });
                }}
                onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
                sx={{ textDecoration: 'none' }}
            >

                <Box
                    component="img"
                    src={imageUrl} // デフォルトの画像パスを設定してください
                    alt={
                        company?.name?.raw
                    }
                    height={isMobile ? 180 : 275}
                    sx={{
                        borderRadius: '8px',
                        objectFit: 'cover', // はみ出した部分は切り取る
                        objectPosition: 'center center', // 画像の中心をコンテナの中心に合わせる
                        height: isMobile ? '180px' : '275px', // モバイルかどうかによって高さを設定
                        width: '100%' // 幅はコンテナに合わせて100%
                    }}
                />
                <Box>
                    <Box sx={{ display: 'flex', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {/* タグが存在し、空でない場合のみレンダリング */}
                        {itemTags && itemTags.length > 0 && itemTags.map((tag: string, index: number) => (
                            <Chip key={index} label={tag} variant="outlined" sx={{ mr: 1 }} />
                        ))}
                    </Box>
                    <Typography
                        gutterBottom variant="h5" component="h2" sx={{ fontWeight: 'bold', mt: 1, fontSize: { xs: '1.2rem', sm: '1.5rem' } }}
                        color={isHovered ? 'primary' : 'black'} // ホバー状態に応じて色を変更
                    >
                        {company?.name?.raw}
                    </Typography>
                    <Box sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        height: '3.6em',
                    }}>
                        <Typography variant="body2" color='black'>
                            {company?.companydetail?.raw}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Typography variant="subtitle2" color='text.secondary' sx={{ pt: 1, fontWeight: 'bold' }}>
                {`${company?.viewcount?.raw || 0} views`}
            </Typography>
            <Box display="flex" justifyContent="space-between" paddingBottom={1}>
                <Box display="flex" alignItems="center">
                    <Avatar src={company?.iconurl?.raw} aria-label="recipe">
                        {company?.iconurl?.raw ? <img src={company?.iconurl?.raw} alt="company logo" /> : company?.name?.raw.charAt(0)}
                    </Avatar>
                    <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{
                            ml: 1,
                            fontWeight: 'bold',
                            textDecoration: 'none', // 通常時は下線を非表示にする
                            color: 'gray', // テキストの色を継承する
                            '&:hover': {
                                textDecoration: 'underline', // ホバー時に下線を表示する
                                color: 'black', // ホバー時に色を変更する
                            },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: { xs: 'calc(60vw - 6rem - 16px)', sm: '150px' }, // テキストの最大幅を指定
                            fontSize: { xs: '0.85rem', sm: '1rem' }
                        }}
                        component={Link}
                        to={`/post/company/${itemId}`}
                    >
                        {company?.name?.raw}
                    </Typography>
                </Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <BookmarkButton itemId={itemId} isBookmarked={isBookmarked} onBookmarkToggle={toggleBookmark} />
                    {/* ボタンのテキストが長い場合の対応は不要 */}
                    <Button variant="contained" onClick={handleChat} className='talk-to-ai-btn'>
                        AIと話す
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
};

export default CompanyCard;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { firestore } from '../../firebase';
import { doc, getDoc, collection, onSnapshot, serverTimestamp, query, updateDoc, where, getDocs } from 'firebase/firestore';
import { Box, Button, Typography, IconButton, Divider, Table, TableBody, TableCell, TableContainer, TableRow, Paper, TableHead, Container, FormControlLabel, Switch } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

// import axios from 'axios';
// import { loadStripe } from '@stripe/stripe-js';

import { useUser } from '../../Context/UserContext';
import { useSnackbar } from '../../Context/SnackbarContext';
import NavigationBar from '../../components/Organisms/NavigationBar';
import UserRow from '../../components/Company/Dashboard/UserRow';
import CompanyTopNavigationContent from '../../components/Organisms/CompanyTopNavigationContent.tsx';
// import SubscriptionPlan from '../../components/Company/Dashboard/SubscriptionPlan';
import AuthIdDialog from '../../components/Company/AuthIdDialog';
import Footer from '../../components/Organisms/Footer.tsx';


// Stripe公開可能キーを設定
// const stripePromise = loadStripe('pk_test_51ObepWGBe8AZCWiIR5nqhxHrObkRFTJhLj29srdd9Z3DATXgX4NqSOuBujp6IVBRYieSeRaQujDGHfIZ4dAzQWSi00uEyWA75Z');
// const stripePromise = loadStripe('pk_live_51ObepWGBe8AZCWiIAB0bHF882l3Zq9MTJVWA4JorEUM6PCxeOQNqGEG58oX1H4vwXcy0txPgxpIhwsOMA18hncrd00WovkYhHT');

const roleDisplayNames = {
    admin: '管理者',
    editor: '編集ユーザー',
    chat_user: 'チャットユーザー',
    viewer: '閲覧ユーザー',
};

const fetchCompanyUsers = async (companyId) => {
    const q = query(collection(firestore, 'users'), where('companyId', '==', companyId));
    const querySnapshot = await getDocs(q);
    const users = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));
    return users;
};

const fetchCompanyInfo = async (companyId, setCompanyInfo, setIsEnabled) => {
    const docRef = doc(firestore, "companies", companyId);
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const id = docSnap.id;
            setCompanyInfo({ id, ...docSnap.data() });
            setIsEnabled(docSnap.data().allowsDirectChat);
        }
    } catch (error) {
        console.error("Error getting document:", error);
        // エラーハンドリングを行う
    }
};

// const handleCheckout = async (customerId, priceId) => {
//     const stripe = await stripePromise;
//     const successUrl = window.location.href;
//     const cancelUrl = window.location.href;
//     try {
//         // バックエンドからセッションIDを取得する
//         // const response = await fetch('http://127.0.0.1:5001/ciel-dac23/asia-northeast1/create_stripe_checkout_session', {
//         const response = await fetch('https://create-stripe-checkout-session-frnrslgk3q-an.a.run.app', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ customerId, priceId, successUrl, cancelUrl })
//         });
//         const session = await response.json();

//         // Stripe Checkoutページにリダイレクト
//         const result = await stripe.redirectToCheckout({
//             sessionId: session.sessionId
//         });

//         if (result.error) {
//             alert(result.error.message);
//         }
//     } catch (error) {
//         console.error('Error:', error);
//         alert('セッションの作成に失敗しました。もう一度お試しください。');
//     }
// };

// const handleOpenCustomerPortal = async (customerId) => {
//     try {
//         // const response = await axios.post('http://127.0.0.1:5001/ciel-dac23/asia-northeast1/create_stripe_portal_session', {
//         const response = await axios.post('https://create-stripe-portal-session-frnrslgk3q-an.a.run.app', {
//             customerId: customerId,
//             returnUrl: window.location.href
//         });


//         const { sessonUrl } = response.data;
//         window.open(sessonUrl, '_blank');

//     } catch (error) {
//         console.error('Failed to get customer portal URL:', error);
//         alert('エラーが発生しました。もう一度お試しください。');
//     }
// };

// function formatTimestamp(unixTimestamp) {
//     // UNIXタイムスタンプをミリ秒に変換してDateオブジェクトを作成
//     const date = new Date(unixTimestamp * 1000);

//     // 日時を yyyy-MM-dd HH:mm:ss 形式にフォーマット
//     const year = date.getFullYear();
//     const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     const day = date.getDate().toString().padStart(2, '0');

//     return `${year}-${month}-${day}`;
// }


const Dashboard = () => {
    // const hasEditPermission = devConfig.hasEditPermission;
    const [companyInfo, setCompanyInfo] = React.useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [authIds, setAuthIds] = useState([]);
    const [users, setUsers] = useState([]);
    const [isEnabled, setIsEnabled] = useState(false);
    // const [isBillingInfoValid, setIsBillingInfoValid] = useState();

    const { id: companyId } = useParams();
    const { showSnackbar } = useSnackbar();
    const { user } = useUser();

    const hasEditPermission = user?.userType === 'company' && user?.companyId === companyId;

    // fetch user and company data from Firestore
    useEffect(() => {
        const fetchData = async () => {
            const fetchedUsers = await fetchCompanyUsers(companyId);
            setUsers(fetchedUsers);
        };

        if (companyId) {
            fetchCompanyInfo(companyId, setCompanyInfo, setIsEnabled);
            fetchData();
        }
    }, [companyId]);

    // 認証ID一覧を取得
    useEffect(() => {
        if (!companyId) return;

        const authIdsRef = collection(firestore, "companies", companyId, "authIds");
        const q = query(authIdsRef, where("isDeleted", "==", false));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedAuthIds = [];
            querySnapshot.forEach((doc) => {
                fetchedAuthIds.push({ id: doc.id, ...doc.data() });
            });
            setAuthIds(fetchedAuthIds);
        });

        return () => unsubscribe();
    }, [companyId]);

    // // 請求先情報を取得
    // useEffect(() => {
    //     setIsBillingInfoValid(!!companyInfo?.stripeCustomerId);
    // }, [companyInfo]);

    // authIdを論理削除する関数
    const handleDelete = async (authId) => {
        const authIdRef = doc(firestore, "companies", companyId, "authIds", authId);
        await updateDoc(authIdRef, {
            isDeleted: true,
            deletedAt: serverTimestamp()
        });
        showSnackbar({ message: "認証IDを削除しました", type: 'success', title: '削除成功' });
    };

    const handleToggle = async (event) => {
        const newValue = event.target.checked;
        setIsEnabled(newValue);

        try {
            const companyDocRef = doc(firestore, 'companies', companyId);
            await updateDoc(companyDocRef, {
                allowsDirectChat: newValue, // 'allowsDirectChat'フィールドを更新
            });
        } catch (error) {
            console.error('Error updating document: ', error);
        }
    };

    return (
        <>
            <NavigationBar />
            <Container maxWidth="xl" sx={{ mt: 15, mb: 15 }}>
                <AuthIdDialog
                    companyId={companyId}
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                />
                <CompanyTopNavigationContent hasEditPermission={hasEditPermission} company={companyInfo} />

                {/* ダイレクトチャットを受け入れるかどうかのボタン */}
                <Paper elevation={0} sx={{ mt: 2, width: '100%', p: 2, border: 1, borderColor: '#ECEEF1', borderRadius: '8px' }}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                            ダイレクトチャット
                        </Typography>
                        <FormControlLabel
                            control={<Switch checked={isEnabled} onChange={handleToggle} />}
                            label={isEnabled ? '許可する' : '許可しない'}
                        />
                    </Box>
                </Paper>

                {/* <Paper elevation={0} sx={{ mt: 2, width: '100%', border: 1, borderColor: '#ECEEF1', borderRadius: '8px' }}>
                    <Box display={'flex'} justifyContent={'space-between'} sx={{ mb: 2, p: 2, }}>
                        <Box>
                            <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>プラン</Typography>
                            <Typography variant="body2" component="p" sx={{ color: 'text.secondary' }}>
                                {!companyInfo?.planPrice ?
                                    'Cielを100%利用するためにプランを選択してください。'
                                    : (
                                        <>
                                            利用中のプランは{companyInfo?.planPrice === 'price_1PAUU6GBe8AZCWiIPqYf1X2N' ? 'トライアル' : 'スタンダード'}プランです。<br/>
                                            利用期間は{formatTimestamp(companyInfo?.periodStart)}から{formatTimestamp(companyInfo?.periodEnd)}までです。
                                            プランの変更は右のカスタマーポータルから行えます。
                                        </>
                                    )}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    startIcon={<AddCircleOutlineIcon />}
                                    onClick={() => handleOpenCustomerPortal(companyInfo?.stripeCustomerId)}
                                    disabled={!isBillingInfoValid}
                                    variant={'outlined'}
                                    size='small'
                                >
                                    カスタマーポータルへ
                                </Button>
                            </Stack>
                        </Box>
                    </Box>

                    {!companyInfo?.planPrice && (
                        <>
                            <Divider />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: 0 }}>
                                <>
                                    <SubscriptionPlan
                                        title={'トライアル（年額）'}
                                        price={300000}  // 配列の最初の価格を使う
                                        // onClick={() => handleCheckout(companyInfo?.stripeCustomerId, 'price_1PAZBqGBe8AZCWiIFzn5d7jC')} // test用
                                        onClick={() => handleCheckout(companyInfo?.stripeCustomerId, 'price_1PAUU6GBe8AZCWiIPqYf1X2N')}
                                        disabled={!isBillingInfoValid}
                                    />
                                    <SubscriptionPlan
                                        title={'スタンダード（年額）'}
                                        price={600000}  // 配列の最初の価格を使う
                                        // onClick={() => handleCheckout(companyInfo?.stripeCustomerId, 'price_1PAa4rGBe8AZCWiIPOIVcwtE')} // test用
                                        onClick={() => handleCheckout(companyInfo?.stripeCustomerId, 'price_1PAUVCGBe8AZCWiI8UJ3vs6V')}
                                        disabled={!isBillingInfoValid}
                                    />
                                </>
                            </Box>
                        </>
                    )}
                </Paper> */}

                {/* 認証ID一覧 */}
                <Paper elevation={0} sx={{ mt: 2, width: '100%', border: 1, borderColor: '#ECEEF1', borderRadius: '8px' }}>
                    <Box display={'flex'} justifyContent={'space-between'} sx={{ mb: 2, p: 2, }}>
                        <Box>
                            <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>認証ID一覧</Typography>
                            <Typography variant="body2" component="p" sx={{ color: 'text.secondary' }}>
                                Cielへ新たなユーザーを追加するための認証IDを管理します。ここでの認証IDは、ユーザーがアカウントを作成する際に使用されます。
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Button
                                startIcon={<AddCircleOutlineIcon />}
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() => setOpenDialog(true)}>
                                ユーザー追加
                            </Button>
                        </Box>
                    </Box>
                    <Divider />
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>認証ID</TableCell>
                                    <TableCell align="left">役割</TableCell>
                                    <TableCell align="center">利用回数</TableCell>
                                    <TableCell align="center">削除</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {authIds.map((authId) => (
                                    <TableRow
                                        key={authId.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {authId.authId}
                                        </TableCell>
                                        <TableCell align="left">{roleDisplayNames[authId.role]}</TableCell>
                                        <TableCell align="center">{authId.usedCount}</TableCell>
                                        <TableCell align="center">
                                            <IconButton onClick={() => handleDelete(authId.id)} color='error'>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                {/* ユーザー一覧 */}
                <Paper elevation={0} sx={{ mt: 2, width: '100%', border: 1, borderColor: '#ECEEF1', borderRadius: '8px' }}>
                    <Box sx={{ mb: 2, p: 2, }}>
                        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>ユーザー一覧</Typography>
                        <Typography variant="body2" component="p" sx={{ color: 'text.secondary' }}>
                            {companyInfo?.name}に登録されているユーザーの一覧です。ユーザーの削除はそれぞれの画面からしか行えません。
                        </Typography>
                    </Box>
                    <Divider />
                    <Box mb={6}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left">苗字</TableCell>
                                        <TableCell align='left'>名前</TableCell>
                                        <TableCell align="center">役割</TableCell>
                                        <TableCell align="left">メールアドレス</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user) => (
                                        <UserRow key={user.id} row={user} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
            </Container>
            <Footer />
        </>
    );
}

export default Dashboard;
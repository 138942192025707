// services/firebase/companyService.ts
import {
    doc,
    updateDoc,
    increment,
    getDoc,
    collectionGroup,
    where,
    query,
    getDocs,
    serverTimestamp,
} from 'firebase/firestore';
import { firestore } from '../../firebase';
import { storage } from '../../firebase';
import {
    ref,
    listAll,
    deleteObject,
    uploadBytes,
    getDownloadURL,
} from 'firebase/storage';

/**
 * 指定された企業の `viewCount` フィールドをインクリメントします。
 * 
 * @async
 * @function incrementViewCount
 * @param {string} itemId - インクリメントする対象の企業のID。
 * 
 * @returns {Promise<void>} - 操作が成功した場合、何も返しません。
 * 
 * @throws {Error} - Firebase Firestore操作が失敗した場合にエラーを投げます。
 * 
 * @example
 * await incrementViewCount('companyId123');
 * console.log('viewCount incremented');
 */
export const incrementViewCount = async (itemId: string) => {
    const docRef = doc(firestore, 'companies', itemId);
    await updateDoc(docRef, {
        viewCount: increment(1),
    });
};

// 企業IDから企業情報を取得
export const fetchCompanyInfo = async (companyId: string) => {
    const docRef = doc(firestore, 'companies', companyId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
    } else {
        throw new Error('Company document not found');
    }
};

// 企業情報を更新
export const updateCompanyInfo = async (companyId: string, updatedData: any) => {
    const companyDocRef = doc(firestore, 'companies', companyId);
    await updateDoc(companyDocRef, updatedData);
};

// サムネイル画像を全て削除してから新しい画像をアップロードする関数
export const uploadThumbnailImageToFirebase = async (companyId: string, file: File) => {
    const folderRef = ref(storage, `company_images/${companyId}/`);

    // 1. サムネイルに関連する既存ファイルを全て削除
    try {
        const listResult = await listAll(folderRef);
        const thumbnailFiles = listResult.items.filter(itemRef => itemRef.name.startsWith('thumbnail'));

        // 各サムネイルファイルを削除
        const deletePromises = thumbnailFiles.map(fileRef => deleteObject(fileRef));
        await Promise.all(deletePromises);
        console.log('既存のサムネイルファイルを削除しました');
    } catch (error) {
        console.error('既存のサムネイルファイルを削除する際にエラーが発生しました: ', error);
        throw new Error('サムネイルファイルの削除に失敗しました');
    }

    // 2. 新しいサムネイルファイルをアップロード
    const storageRef = ref(storage, `company_images/${companyId}/thumbnail`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
};

// Firestoreにサムネイル画像のURLを保存する関数
export const saveThumbnailImageUrlToFirestore = async (companyId: string, thumbnailUrl: string) => {
    const companyDocRef = doc(firestore, "companies", companyId);
    await updateDoc(companyDocRef, { thumbnailUrl });
};

export const authenticateCompanyID = async (authId: string) => {
    const q = query(collectionGroup(firestore, 'authIds'), where('authId', '==', authId), where('isDeleted', '==', false));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
        throw new Error("無効な認証IDです。");
    }

    const firstDoc = querySnapshot.docs[0];
    const companyRef = firstDoc.ref.parent.parent;

    if (!companyRef) {
        throw new Error("企業ドキュメントが見つかりません。");
    }

    const companyDoc = await getDoc(companyRef);
    if (!companyDoc.exists()) {
        throw new Error("企業ドキュメントが見つかりません。");
    }

    return { role: firstDoc.data()?.role, companyRef, companyData: companyDoc.data(), authIdDocRef: firstDoc.ref };
};

export const incrementAuthIdUsage = async (authIdDocRef: any) => {
    if (authIdDocRef) {
        await updateDoc(authIdDocRef, {
            usedCount: increment(1),
            updatedAt: serverTimestamp()
        });
    }
};
import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

// Material-UI
import { Paper, Tabs, Tab, Avatar, Box, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SchoolIcon from '@mui/icons-material/School';

// Local Components
import ResponsiveContainer from '../Templates/ResponsiveContainer';
import ProfileImageUpload from '../Input/ProfileImageUpload';
import { ASPIRATIONS_OPTIONS, SCHOOL_OPTIONS, GRADE_OPTIONS } from '../../utils/options';
import { getLabelByValue } from '../../utils/helpers/getLabelByValue';

const ProfileTopNavigationContent = ({ user }) => {
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [image, setImage] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { id: userId } = useParams(); // URLからユーザーIDを取得
    // 現在のパスに基づいてタブの選択状態を決定
    const currentTab = location.pathname.includes('/edit') ? 1 : 0;
    const savePath = `user_image/${userId}/avator.png`;

    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            navigate(`/profile/${userId}`);
        } else if (newValue === 1) {
            navigate(`/profile/edit/${userId}`);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);
            setImageModalOpen(true);
        }
    };

    return (
        <Paper >
            <ProfileImageUpload
                imageModalOpen={imageModalOpen}
                setImageModalOpen={setImageModalOpen}
                image={image}
                width={250}
                height={250}
                savePath={savePath}
                collectionName={'users'}
                docId={user?.documentId}
                saveUrlFieldName={'userIconUrl'}
                saveUrlStateFieldName={'hasUsericonurl'}
            />

            <ResponsiveContainer>
                <Box sx={{ minHeight: 300, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {user?.uid === userId && (
                            <input
                                accept="image/*"
                                type="file"
                                id="icon-button-file"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                            />
                        )}
                        <label htmlFor="icon-button-file">
                            <Avatar sx={{
                                mr: 3,
                                width: 100,
                                height: 100,
                                transition: 'transform .5s ease',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                            }}
                                aria-label="upload picture"
                                component="span"
                                src={user?.userIconUrl}
                            >
                                {user?.userIconUrl ? <img src={user?.userIconUrl} alt="user icon" /> : <Box sx={{ fontSize: '2rem' }}>{user?.lastName?.charAt(0)}</Box>}
                            </Avatar>
                        </label>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: { xs: 'flex-end', md: '' } }}>
                            {user?.userType === 'highSchool' && (
                                <Typography variant="subtitle2" color='text.secondary' sx={{ fontWeight: 'bold' }}>
                                    {getLabelByValue(ASPIRATIONS_OPTIONS, user?.aspirations)}
                                </Typography>
                            )}
                            <Typography variant="h3" component="h2" sx={{ fontWeight: 'bold' }}>{user?.lastName}　{user?.firstName}</Typography>
                            {user?.userType === 'highSchool' && (
                                <Box sx={{ display: 'flex', mt: 1 }} gap={1}>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <LocationOnIcon color="action" />
                                        <Typography variant="caption" color='text.secondary'>{user?.prefecture} {user?.city}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <SchoolIcon color="action" />
                                        <Typography variant="caption" color='text.secondary'>
                                            {getLabelByValue(SCHOOL_OPTIONS, user?.schoolName)} {getLabelByValue(GRADE_OPTIONS, user?.grade)}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Tabs
                    value={currentTab}
                    onChange={handleChange}
                    aria-label="profile top navigation"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="プロフィール" />
                    <Tab label="編集" />
                </Tabs>
            </ResponsiveContainer>
        </Paper>
    );
}

export default ProfileTopNavigationContent;
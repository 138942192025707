import { doc, setDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { Timestamp } from 'firebase/firestore';

export interface UserData {
    email: string;
    phoneNumber?: string;
    lastName?: string;
    firstName?: string;
    birthDate?: Timestamp | string;
    gender?: string;
    userType?: string;
    age?: number;
    [key: string]: any;  // Allows additional fields if necessary
}

// Create a new user in Firestore
export const createUserInFirestore = async (userId: string, userData: UserData): Promise<void> => {
    const userDocRef = doc(firestore, 'users', userId);
    try {
        await setDoc(userDocRef, {
            ...userData,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            isDeleted: false,
        });
    } catch (error: any) {
        throw new Error(`Failed to create user: ${error.message}`);
    }
};

// Fetch a user from Firestore by userId
export const getUserFromFirestore = async (userId: string): Promise<UserData | null> => {
    const userDocRef = doc(firestore, 'users', userId);
    try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
            return userDoc.data() as UserData;
        }
        return null;
    } catch (error: any) {
        throw new Error(`Failed to fetch user: ${error.message}`);
    }
};

// Update an existing user's information in Firestore
export const updateUserInFirestore = async (userId: string, updatedData: Partial<UserData>): Promise<void> => {
    const userDocRef = doc(firestore, 'users', userId);
    try {
        await setDoc(userDocRef, {
            ...updatedData,
            updatedAt: Timestamp.now(),
        }, { merge: true });
    } catch (error: any) {
        throw new Error(`Failed to update user: ${error.message}`);
    }
};


import React from 'react';

import { Paper, Tab, Tabs, Container } from '@mui/material';

import CompaniesTable from './components/companiesTable/CompaniesTable';
import ProcessingCompaniesTable from './components/preProcessCompaniesTable/preProcessCompaniesTable';
import DeletedCompaniesTable from './components/deletedCompaniesTable/DeletedCompaniesTable';
import NavigationBar from '../../components/Organisms/NavigationBar';
import Footer from '../../components/Organisms/Footer.tsx';

const Dashboard = () => {
    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };


    return (
        // <ResponsiveContainer>
        <>
            <NavigationBar />
            <Container maxWidth="xl" sx={{ mt: 15 }}>
                <Paper>
                    <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="simple tabs example">
                        <Tab label="追加済企業" />
                        <Tab label="処理中企業" />
                        <Tab label="削除済企業" />
                    </Tabs>
                    {selectedTab === 0 && <CompaniesTable />}
                    {selectedTab === 1 && <ProcessingCompaniesTable />}
                    {selectedTab === 2 && <DeletedCompaniesTable />}
                </Paper>
            </Container>
            <Footer />
        </>
        // </ResponsiveContainer>
    );
}

export default Dashboard;

// options.js などのファイルに保存
export const ASPIRATIONS_OPTIONS = [
    { value: 'employment', label: '就職' },
    { value: 'education', label: '進学' },
    { value: 'undecided', label: '未定' },
];


export const SCHOOL_OPTIONS = [
    { value: '06001', label: '山形県立山形東高等学校' },
    { value: '06002', label: '山形県立山形西高等学校' },
    { value: '06003', label: '山形県立山形南高等学校' },
    { value: '06004', label: '山形県立山形北高等学校' },
    { value: '06005', label: '山形県立山形中央高等学校' },
    { value: '06006', label: '山形県立山形工業高等学校' },
    { value: '06007', label: '山形県立霞城学園高等学校' },
    { value: '06008', label: '山形県立寒河江高等学校' },
    { value: '06009', label: '山形県立寒河江工業高等学校' },
    { value: '06010', label: '山形県立天童高等学校' },
    { value: '06011', label: '山形県立上山明新館高等学校' },
    { value: '06012', label: '山形県立山辺高等学校' },
    { value: '06013', label: '山形県立左沢高等学校' },
    { value: '06014', label: '山形県立谷地高等学校' },
    { value: '06015', label: '山形県立新庄北高等学校' },
    { value: '06016', label: '山形県立新庄南高等学校' },
    { value: '06017', label: '山形県立新庄神室産業高等学校' },
    { value: '06018', label: '山形県立村山産業高等学校' },
    { value: '06019', label: '山形県立東桜学館高等学校' },
    { value: '06020', label: '山形県立北村山高等学校' },
    { value: '06021', label: '山形県立新庄南高等学校金山校' },
    { value: '06022', label: '山形県立新庄神室産業高等学校真室川校' },
    { value: '06023', label: '山形県立新庄北高等学校最上校' },
    { value: '06024', label: '山形県立米沢興譲館高等学校' },
    { value: '06025', label: '山形県立米沢東高等学校' },
    { value: '06026', label: '山形県立米沢工業高等学校' },
    { value: '06027', label: '山形県立米沢商業高等学校' },
    { value: '06028', label: '山形県立長井高等学校' },
    { value: '06029', label: '山形県立長井工業高等学校' },
    { value: '06030', label: '山形県立南陽高等学校' },
    { value: '06031', label: '山形県立置賜農業高等学校' },
    { value: '06032', label: '山形県立高畠高等学校' },
    { value: '06033', label: '山形県立荒砥高等学校' },
    { value: '06034', label: '山形県立小国高等学校' },
    { value: '06035', label: '山形県立酒田東高等学校' },
    { value: '06036', label: '山形県立酒田西高等学校' },
    { value: '06037', label: '山形県立酒田光陵高等学校' },
    { value: '06038', label: '山形県立加茂水産高等学校' },
    { value: '06039', label: '山形県立鶴岡工業高等学校' },
    { value: '06040', label: '山形県立鶴岡中央高等学校' },
    { value: '06041', label: '山形県立庄内農業高等学校' },
    { value: '06042', label: '山形県立致道館高等学校' },
    { value: '06043', label: '山形県立庄内総合高等学校' },
    { value: '06044', label: '山形県立遊佐高等学校' },
    { value: '06045', label: '山形市立商業高等学校' },
    { value: '06046', label: '東海大学山形高等学校' },
    { value: '06047', label: '日本大学山形高等学校' },
    { value: '06048', label: '山形学院高等学校' },
    { value: '06049', label: '東北文教大学山形城北高等学校' },
    { value: '06050', label: '惺山高等学校' },
    { value: '06051', label: '山形明正高等学校' },
    { value: '06052', label: '米沢中央高等学校' },
    { value: '06053', label: '九里学園高等学校' },
    { value: '06054', label: '鶴岡東高等学校' },
    { value: '06055', label: '羽黒高等学校' },
    { value: '06056', label: '酒田南高等学校' },
    { value: '06057', label: '和順館高等学校' },
    { value: '06058', label: '新庄東高等学校' },
    { value: '06059', label: '創学館高等学校' },
    { value: '06060', label: '基督教独立学園高等学校' },
];

export const GRADE_OPTIONS = [
    { value: '1', label: '高校1年生' },
    { value: '2', label: '高校2年生' },
    { value: '3', label: '高校3年生' },
];

export const GENDER_OPTIONS = [
    { value: 'male', label: '男性' },
    { value: 'female', label: '女性' },
    { value: 'other', label: 'その他' },
];

export const EDUCATION_OPTIONS = [
    { value: 'highSchool', label: '高校' },
    { value: 'vocationalSchool', label: '専門学校・短期大学' },
    { value: 'university', label: '大学' },
    { value: 'graduateSchool', label: '大学院' },
    { value: 'workingProfessional', label: '社会人' },
    { value: 'other', label: 'その他' },
];

export const EMPLOYEE_TYPE_OPTIONS = [
    { value: 'partTime', label: 'アルバイト' },
    { value: 'intern', label: 'インターン' },
    { value: 'volunteer', label: 'ボランティア' },
    { value: 'other', label: 'その他' },
];

export const INTERESTING_WORK_CATEGORY_OPTIONS = [
    { value: 'commercial', label: '小売業' },
    { value: 'manufacturing', label: '製造業' },
    { value: 'construction', label: '建築業' },
    { value: 'agriculture', label: '農業' },
    { value: 'forestry', label: '林業' },
    { value: 'fisheries', label: '漁業' },
    { value: 'mining', label: '鉱業' },
    { value: 'utilities', label: '電力・ガス・水道業' },
    { value: 'transportation', label: '運輸業' },
    { value: 'information', label: '情報通信業' },
    { value: 'finance', label: '金融業' },
    { value: 'insurance', label: '保険業' },
    { value: 'real_estate', label: '不動産業' },
    { value: 'professional_services', label: '専門サービス業' },
    { value: 'educational_services', label: '教育サービス業' },
    { value: 'healthcare', label: '医療・福祉' },
    { value: 'social_assistance', label: '社会福祉' },
    { value: 'arts', label: '芸術・エンターテイメント' },
    { value: 'recreation', label: 'レクリエーション' },
    { value: 'hospitality', label: '宿泊業' },
    { value: 'food_services', label: '飲食業' },
    { value: 'public_administration', label: '公務' },
    { value: 'other_services', label: 'その他サービス業' }
];

export const INTERESTING_LEARN_CATEGORY_OPTIONS = [
    { value: 'literature_history_geography', label: '文学・歴史・地理' },
    { value: 'foreign_literature', label: '外国文学' },
    { value: 'japanese_literature', label: '日本文学' },
    { value: 'literary_studies', label: '文芸学' },
    { value: 'philosophy', label: '哲学' },
    { value: 'religious_studies', label: '宗教学' },
    { value: 'cultural_anthropology', label: '文化人類学' },
    { value: 'comparative_culture_studies', label: '比較文化学' },
    { value: 'library_information_science', label: '図書館情報学' },
    { value: 'history', label: '歴史学' },
    { value: 'archaeology', label: '考古学' },
    { value: 'geography', label: '地理学' },
    { value: 'law_politics', label: '法律・政治' },
    { value: 'law', label: '法学' },
    { value: 'political_science', label: '政治学' },
    { value: 'economics_business_commerce', label: '経済・経営・商学' },
    { value: 'economics', label: '経済学' },
    { value: 'business_administration', label: '経営学' },
    { value: 'commerce', label: '商学' },
    { value: 'finance', label: '金融学' },
    { value: 'sociology_mass_communication_tourism', label: '社会学・マスコミ・観光' },
    { value: 'sociology', label: '社会学' },
    { value: 'community_society', label: '地域社会' },
    { value: 'communication_studies', label: 'コミュニケーション学' },
    { value: 'business_studies', label: 'ビジネス学' },
    { value: 'tourism_studies', label: '観光学' },
    { value: 'mass_communication_studies', label: 'マスコミ学' },
    { value: 'media_studies', label: 'メディア学' },
    { value: 'human_psychology', label: '人間・心理' },
    { value: 'human_science', label: '人間科学' },
    { value: 'psychology', label: '心理学' },
    { value: 'welfare_care', label: '福祉・介護' },
    { value: 'welfare_studies', label: '福祉学' },
    { value: 'care_welfare', label: '介護福祉' },
    { value: 'social_welfare', label: '社会福祉' },
    { value: 'care_studies', label: '介護学' },
    { value: 'language_foreign_language', label: '語学・外国語' },
    { value: 'foreign_languages', label: '語学(外国語)' },
    { value: 'japanese_language', label: '語学(日本語)' },
    { value: 'linguistics', label: '言語学' },
    { value: 'international_studies', label: '国際・国際関係' },
    { value: 'international_relations', label: '国際関係学' },
    { value: 'international_culture_studies', label: '国際文化学' },
    { value: 'international_trade_studies', label: '国際貿易学' },
    { value: 'mathematics_physics_chemistry', label: '数学・物理・化学' },
    { value: 'mathematics', label: '数学' },
    { value: 'physics', label: '物理学' },
    { value: 'chemistry', label: '化学' },
    { value: 'earth_science', label: '地学' },
    { value: 'engineering_architecture', label: '工学・建築' },
    { value: 'mechanical_engineering', label: '機械工学' },
    { value: 'electrical_engineering', label: '電気工学' },
    { value: 'electronics_engineering', label: '電子工学' },
    { value: 'materials_engineering', label: '材料工学' },
    { value: 'metallurgical_engineering', label: '金属工学' },
    { value: 'aerospace_marine_engineering', label: '航空・船舶・自動車工学' },
    { value: 'design_engineering', label: 'デザイン工学' },
    { value: 'image_sound_engineering', label: '画像・音響工学' },
    { value: 'management_engineering', label: '経営工学' },
    { value: 'applied_sciences', label: '応用理学' },
    { value: 'applied_chemistry', label: '応用化学' },
    { value: 'applied_physics', label: '応用物理学' },
    { value: 'architecture_design', label: '建築学・意匠' },
    { value: 'interior_design', label: 'インテリア' },
    { value: 'civil_engineering', label: '土木工学' },
    { value: 'industrial_arts', label: '工芸学' },
    { value: 'seafarer_pilot_training', label: '船員・パイロット養成' },
    { value: 'information_communication', label: '情報学・通信' },
    { value: 'information_science', label: '情報学' },
    { value: 'communication_engineering', label: '通信工学' },
    { value: 'information_engineering', label: '情報工学' },
    { value: 'systems_control_engineering', label: 'システム・制御工学' },
    { value: 'earth_environment_energy', label: '地球・環境・エネルギー' },
    { value: 'space_earth_science', label: '宇宙・地球学' },
    { value: 'environmental_science', label: '環境学' },
    { value: 'environmental_engineering', label: '環境工学' },
    { value: 'environmental_information_studies', label: '環境情報学' },
    { value: 'energy_resources_engineering', label: 'エネルギー・資源工学' },
    { value: 'nuclear_engineering', label: '原子力工学' },
    { value: 'mining_engineering', label: '鉱山学' },
    { value: 'agriculture_fisheries_biology', label: '農学・水産学・生物' },
    { value: 'agriculture', label: '農学' },
    { value: 'agricultural_engineering', label: '農業工学' },
    { value: 'agricultural_economics', label: '農業経済学' },
    { value: 'forestry', label: '林学・林産学' },
    { value: 'agricultural_chemistry', label: '農芸化学' },
    { value: 'animal_husbandry', label: '畜産学' },
    { value: 'fisheries_science', label: '水産学' },
    { value: 'biology', label: '生物学' },
    { value: 'biotechnology', label: '生物工学' },
    { value: 'biological_resources_science', label: '生物資源学' },
    { value: 'biological_production_science', label: '生物生産学' },
    { value: 'veterinary_science', label: '獣医学' },
    { value: 'animal_nursing', label: '動物看護学' },
    { value: 'medicine_dentistry_pharmacy_nursing_rehabilitation', label: '医学・歯学・薬学・看護・リハビリ' },
    { value: 'medicine', label: '医学' },
    { value: 'dentistry', label: '歯学' },
    { value: 'nursing', label: '看護学' },
    { value: 'medical_technology', label: '医療技術学' },
    { value: 'rehabilitation_occupational_physical_therapy', label: 'リハビリテーション・作業療法・理学療法' },
    { value: 'acupuncture_moxibustion_judo_therapy', label: '鍼灸・柔道整復学' },
    { value: 'pharmacy', label: '薬学' },
    { value: 'speech_language_hearing_science', label: '言語聴覚学' },
    { value: 'physical_health_sports', label: '体育・健康・スポーツ' },
    { value: 'health_science', label: '健康科学' },
    { value: 'life_science', label: '生命科学' },
    { value: 'public_health', label: '保健衛生学' },
    { value: 'physical_education', label: '体育学' },
    { value: 'sports_science', label: 'スポーツ学' },
    { value: 'life_fashion_beauty', label: '生活・服飾・美容' },
    { value: 'life_science', label: '生活科学' },
    { value: 'home_economics', label: '家政学' },
    { value: 'housing_studies', label: '住居学' },
    { value: 'beauty', label: '美容' },
    { value: 'barbering', label: '理容' },
    { value: 'fashion_clothing_studies', label: '服飾・被服学' },
    { value: 'nutrition_food', label: '栄養・食物' },
    { value: 'nutrition_studies', label: '栄養学' },
    { value: 'food_science', label: '食物学' },
    { value: 'culinary_studies', label: '調理学' },
    { value: 'education_childcare', label: '教育・保育' },
    { value: 'education_studies', label: '教育学' },
    { value: 'teacher_training', label: '教員養成' },
    { value: 'child_studies', label: '児童学' },
    { value: 'childcare_early_childhood_education', label: '保育・幼児教育' },
    { value: 'art_expression_music', label: '芸術・表現・音楽' },
    { value: 'design', label: 'デザイン' },
    { value: 'fine_arts', label: '美術' },
    { value: 'photography', label: '写真' },
    { value: 'film', label: '映像' },
    { value: 'music', label: '音楽' },
    { value: 'theater_dance_studies', label: '舞台・演劇・舞踊学' },
    { value: 'general_liberal_arts', label: '総合・教養' },
    { value: 'liberal_arts', label: '教養学' },
    { value: 'general_science', label: '総合科学' },
    { value: 'general_policy_studies', label: '総合政策学' },
    { value: 'career_design_studies', label: 'キャリアデザイン学' },
];

export const EMAIL_NOTIFICATION_OPTIONS = [
    { value: 'notify', label: '通知する' },
    { value: 'doNotNotify', label: '通知しない' },
];

export const CORPORATE_VALUES = [
    { value: 'none', valueId: 'none', label: '選択してください' },
    { value: 'innovation', valueId: 'innovation', label: '革新' },
    { value: 'integrity', valueId: 'integrity', label: '誠実' },
    { value: 'quality', valueId: 'quality', label: '品質' },
    { value: 'teamwork', valueId: 'teamwork', label: 'チームワーク' },
    { value: 'customerFocus', valueId: 'customerFocus', label: '顧客重視' },
    { value: 'respect', valueId: 'respect', label: '尊敬' },
    { value: 'efficiency', valueId: 'efficiency', label: '効率' },
    { value: 'reliability', valueId: 'reliability', label: '信頼性' },
    { value: 'creativity', valueId: 'creativity', label: '創造性' },
    { value: 'sustainability', valueId: 'sustainability', label: '持続可能性' },
];
// React and React Router imports
import React, { useState } from 'react';
import dayjs from 'dayjs';

// MUI components and icons
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import EmailIconOutlined from '@mui/icons-material/EmailOutlined';
import LockIconOutlined from '@mui/icons-material/LockOutlined';
import PhoneIconOutlined from '@mui/icons-material/PhoneOutlined';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ja from 'dayjs/locale/ja';

// Hook form imports
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';

// Utils and helpers
import { EDUCATION_OPTIONS } from '../../utils/options';

// Local components
import PasswordResetDialog from './PasswordResetDialog';


interface RegistrationDialogProps {
    open: boolean;
    onClose: () => void;
    afterAction?: () => void;
}

interface LoginFormInputs {
    email: string;
    password: string;
    phoneNumber: string;
    lastName: string;
    firstName: string;
    birthDate: dayjs.Dayjs;
    userType: string;
    gender: string;
}

const RegistrationDialog: React.FC<RegistrationDialogProps> = ({ open, onClose, afterAction }) => {
    const [isLogin, setIsLogin] = useState<boolean>(true);
    const [resetDialogOpen, setResetDialogOpen] = useState<boolean>(false); // ダイアログの状態
    const { register, handleSubmit, control, formState } = useForm<LoginFormInputs>({
        mode: "onChange",
        defaultValues: {
            birthDate: dayjs(), // ここでdayjsのデフォルト値を設定することもできます
        }
    });

    const { errors, isValid, isDirty } = formState;

    const { login, signUp, signInWithLineProvider } = useAuth();

    const handleSignUp = (data: LoginFormInputs) => {
        signUp(data, () => {
            onClose();
            if (afterAction) {
                afterAction();
            }
        });
    };

    const handleLogin = async (data: { email: string; password: string; }) => {
        login(data.email, data.password, () => {
            onClose();
            if (afterAction) {
                afterAction();
            }
        });
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Container component="main" maxWidth="xs" sx={{ my: 2 }}>
                    {isLogin ? (
                        <>
                            <Typography component="h2" variant="h5">Cielにログイン</Typography>
                            <form onSubmit={handleSubmit(handleLogin)}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="メールアドレス"
                                    {...register("email", {
                                        required: "メールアドレスを入力してください",
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "無効なメールアドレスです"
                                        }
                                    })}
                                    error={Boolean(errors.email)}
                                    helperText={errors.email?.message}
                                    autoFocus
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="パスワード"
                                    type="password"
                                    {...register("password", {
                                        required: "パスワードを入力してください",
                                        minLength: {
                                            value: 6,
                                            message: "パスワードは6文字以上で入力してください"
                                        }
                                    })}
                                    error={Boolean(errors.password)}
                                    helperText={errors.password?.message}
                                />
                                <Button type="submit" fullWidth variant="contained" color="primary" size='large' sx={{ borderRadius: '8px', marginTop: 2 }}
                                    disabled={!isValid || !isDirty}
                                >ログイン</Button>
                            </form>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    signInWithLineProvider(() => {
                                        onClose();
                                        if (afterAction) {
                                            afterAction();
                                        }
                                    });
                                }}
                                size="large"
                                fullWidth
                                sx={{
                                    borderRadius: '8px',
                                    marginTop: 2,
                                    backgroundColor: '#06C755',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: 'rgba(6, 199, 85, 0.9)', // 透明度10%追加
                                        boxShadow: 'none', // デフォルトのMUIホバーシャドウを無効化
                                    },
                                    '&:active': {
                                        backgroundColor: 'rgba(6, 199, 85, 0.7)', // 透明度30%追加
                                        boxShadow: 'none', // アクティブ時もシャドウを無効化
                                    }
                                }}
                            >
                                LINEでログイン
                            </Button>
                            <Divider sx={{ my: 2 }}>または</Divider>
                            <Button onClick={() => setIsLogin(false)} fullWidth variant="outlined" color="primary" sx={{ borderRadius: '8px' }}>アカウント登録</Button>
                            <Link component="button" variant="body2" onClick={() => setResetDialogOpen(true)} sx={{ mt: 4 }}>
                                パスワードをお忘れですか？
                            </Link>
                            <PasswordResetDialog open={resetDialogOpen} onClose={() => setResetDialogOpen(false)} />
                        </>
                    ) : (
                        <>
                            <Typography variant="h5" component="h2" gutterBottom>Cielに登録</Typography>
                            <Typography variant="caption" component="h6" gutterBottom>Cielを利用するためにはアカウント登録が必要です</Typography>
                            <form onSubmit={handleSubmit(handleSignUp)}>
                                <TextField
                                    label="メールアドレス"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    {...register("email", { required: "メールアドレスは必須です", pattern: /^\S+@\S+\.\S+$/ })}
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIconOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    label="パスワード"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                    {...register("password", { required: "パスワードは必須です", minLength: { value: 6, message: "パスワードは6文字以上である必要があります" } })}
                                    error={!!errors.password}
                                    helperText={errors.password?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIconOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    label="電話番号（ハイフンなし）"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    {...register("phoneNumber", {
                                        required: "電話番号は必須です",
                                        pattern: {
                                            value: /^(\+?\d[- .]*){7,13}$/,
                                            message: "無効な電話番号です"
                                        }
                                    })}
                                    error={!!errors.phoneNumber}
                                    helperText={errors.phoneNumber?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIconOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <Grid container spacing={{ xs: 0, sm: 2 }}> {/* コンテナGrid */}
                                    <Grid item xs={12} sm={6}> {/* 各TextFieldをラップするGridアイテム */}
                                        <TextField
                                            label="苗字"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            {...register("lastName", { required: "苗字は必須です" })}
                                            error={!!errors.lastName}
                                            helperText={errors.lastName?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}> {/* 各TextFieldをラップするGridアイテム */}
                                        <TextField
                                            label="名前"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            {...register("firstName", { required: "名前は必須です" })}
                                            error={!!errors.firstName}
                                            helperText={errors.firstName?.message}
                                        />
                                    </Grid>
                                </Grid>

                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="userType-label">種別</InputLabel>
                                    <Controller
                                        name="userType"
                                        control={control}
                                        rules={{ required: "種別を選択してください" }}
                                        render={({ field }) => (
                                            <Select
                                                labelId="userType-label"
                                                id="userType"
                                                label="種別"
                                                {...field}
                                            >
                                                <MenuItem value="">
                                                    <em>未選択</em>
                                                </MenuItem>
                                                {EDUCATION_OPTIONS.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {errors.userType && <Typography color="error">{errors.userType.message}</Typography>}
                                </FormControl>

                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    // @ts-ignore: エラーを一時的に無視
                                    adapterLocale={ja}
                                    dateFormats={{
                                        monthAndYear: 'YYYY年MM月',
                                        normalDate: 'YYYY年MM月DD日',
                                        keyboardDate: 'YYYY年MM月DD日',
                                    }}
                                    localeText={{
                                        previousMonth: '前へ',
                                        nextMonth: '次へ',
                                        // @ts-ignore: エラーを一時的に無視
                                        cancelText: "キャンセル",
                                        okText: "選択",
                                    }}
                                >
                                    <FormControl component="fieldset" margin="normal" fullWidth>
                                        <Controller
                                            name="birthDate"
                                            control={control}
                                            rules={{ required: "生年月日は必須です" }}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <DatePicker
                                                    views={['year', 'month', 'day']}
                                                    openTo="year" // 年から選べるようにする
                                                    format="YYYY年MM月DD日" // input後のform内の表示を変える
                                                    label="生年月日"
                                                    value={value}
                                                    onChange={onChange}
                                                    // @ts-ignore: エラーを一時的に無視
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={!!error}
                                                            helperText={error ? error.message : null}
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    DialogProps={{
                                                        sx: {
                                                            '& .PrivatePickersToolbar-dateTitleContainer .MuiTypography-root': {
                                                                fontSize: '1.5rem',
                                                            },
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </LocalizationProvider>

                                <FormControl component="fieldset" margin="normal" fullWidth>
                                    <FormLabel component="legend">性別</FormLabel>
                                    <Controller
                                        name="gender"
                                        control={control}
                                        rules={{ required: "性別を選択してください" }}
                                        render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <FormControlLabel value="male" control={<Radio />} label="男性" />
                                                <FormControlLabel value="female" control={<Radio />} label="女性" />
                                                <FormControlLabel value="other" control={<Radio />} label="その他" />
                                            </RadioGroup>
                                        )}
                                    />
                                    {errors.gender && <Typography color="error">{errors.gender.message}</Typography>}
                                </FormControl>
                                <Typography variant="body2" sx={{ mb: 3 }}>
                                    登録することで、<Link href="https://www.cielinc.co.jp/terms" target="_blank" style={{ textDecoration: 'none' }}>利用規約</Link>
                                    および<Link href="https://www.cielinc.co.jp/privacy-policy" target="_blank" style={{ textDecoration: 'none' }}>プライバシーポリシー</Link>に同意したものとします。
                                </Typography>
                                <Button type="submit" variant="contained" color="primary" fullWidth size="large" sx={{ borderRadius: '8px' }} disabled={!isValid || !isDirty} >
                                    アカウント登録
                                </Button>
                            </form>
                            <Button
                                variant='contained'
                                onClick={() => signInWithLineProvider}
                                size="large"
                                fullWidth
                                sx={{
                                    borderRadius: '8px',
                                    marginTop: 2,
                                    backgroundColor: '#06C755',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: 'rgba(6, 199, 85, 0.9)', // 透明度10%追加
                                        boxShadow: 'none', // デフォルトのMUIホバーシャドウを無効化
                                    },
                                    '&:active': {
                                        backgroundColor: 'rgba(6, 199, 85, 0.7)', // 透明度30%追加
                                        boxShadow: 'none', // アクティブ時もシャドウを無効化
                                    }
                                }}
                            >
                                LINEで登録
                            </Button>
                            <Divider sx={{ my: 2 }}>または</Divider>
                            <Button onClick={() => setIsLogin(true)} fullWidth variant="outlined" color="primary" sx={{ borderRadius: '8px' }}>ログイン</Button>
                        </>
                    )}
                </Container>
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog >
    );
}

export default RegistrationDialog;
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useImageUpload } from '../../hooks/useImageUpload';
import { validateImageFile, validateImageDimensions } from '../../utils/imageValidator';

interface ImageUploadModalProps {
    open: boolean;
    handleClose: () => void;
    companyId: string;
}

const ImageUploadModal: React.FC<ImageUploadModalProps> = ({ open, handleClose, companyId }) => {
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { handleImageSave, uploading } = useImageUpload();

    const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const file = event.target.files?.[0];
        if (!file) return;

        // ファイルのバリデーション
        const validation = validateImageFile(file);
        if (!validation.isValid) {
            alert(validation.error);
            return;
        }

        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const dimensions = validateImageDimensions(img.naturalWidth, img.naturalHeight);
            if (!dimensions.isValid) {
                alert(dimensions.error);
                return;
            }

            setSelectedFile(file);
            setImagePreview(URL.createObjectURL(file));
        };
    };

    const saveImage = async () => {
        const result = await handleImageSave(companyId, selectedFile);
        if (result && result.success) {
            // リロード
            window.location.reload();
        } else {
            alert('画像の更新に失敗しました');
        }
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>
                画像をアップロード
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant='body1'>
                    画像の幅と高さは1000px以下にしてください。<br />
                    ファイルサイズは5MB以下にしてください。<br />
                    JPEG、PNG、GIF形式のファイルのみアップロードできます。
                </Typography>
                <Box textAlign="center" sx={{ mt: 3 }}>
                    <input
                        accept="image/*"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="upload-image"
                    />
                    <label htmlFor="upload-image">
                        <Button variant="contained" color="primary" component="span">
                            画像を選択
                        </Button>
                    </label>
                    {imagePreview && (
                        <Box mt={2}>
                            <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>キャンセル</Button>
                <Button onClick={saveImage} color="primary" disabled={uploading}>
                    保存
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageUploadModal;

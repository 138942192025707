// hooks/useImageUpload.ts
import { useState } from "react";
import { uploadThumbnailImageToFirebase, saveThumbnailImageUrlToFirestore } from "../services/firebase/companyService";

export const useImageUpload = () => {
    const [uploading, setUploading] = useState(false);

    const handleImageSave = async (companyId: string, selectedFile: File | null) => {
        if (!selectedFile) return;

        setUploading(true);
        try {
            const thumbnailUrl = await uploadThumbnailImageToFirebase(companyId, selectedFile);
            await saveThumbnailImageUrlToFirestore(companyId, thumbnailUrl);
            return { success: true, thumbnailUrl };
        } catch (error) {
            console.error("Error uploading image: ", error);
            return { success: false, error };
        } finally {
            setUploading(false);
        }
    };

    return { handleImageSave, uploading };
};

import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
    Button,
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import ja from 'dayjs/locale/ja';
import { Dayjs } from 'dayjs';

import { useSnackbar } from '../../Context/SnackbarContext';
import { useCompanySignup } from '../../hooks/useCompanySignup';

import NavigationBar from '../../components/Organisms/NavigationBar';
import Footer from '../../components/Organisms/Footer';
import ResponsiveContainer from '../../components/Templates/ResponsiveContainer';

// 型定義
interface FormData {
    companyId: string;
    email: string;
    password: string;
    lastName: string;
    firstName: string;
    birthDate: Dayjs | null;
    gender: 'male' | 'female' | 'other';
}

const CompanySignupForm: React.FC = () => {
    const { register, handleSubmit, control, formState: { errors }, watch } = useForm<FormData>({
        mode: "onChange",
        defaultValues: {
            birthDate: null,
        }
    });

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { isAuthenticated, companyData, authenticateCompany, submitCompanySignup } = useCompanySignup();

    const companyId = watch("companyId"); // companyId を watch で監視

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        await submitCompanySignup(
            data,
            (companyId) => {
                navigate(`/company/${companyId}/dashboard`);
            },
            (error) => {
                showSnackbar({ message: error.message, type: 'error', title: '登録エラー' });
            }
        );
    };

    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                {!isAuthenticated ? (
                    <Box sx={{ textAlign: 'center', marginBottom: 2, minHeight: '60vh' }}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            企業アカウント登録
                        </Typography>
                        <TextField
                            label="企業ID"
                            variant="outlined"
                            {...register("companyId")}
                            fullWidth
                            margin="normal"
                            error={!!errors.companyId}
                            helperText={errors.companyId?.message}
                        />
                        <Button onClick={() => authenticateCompany(companyId)} variant="contained" color="primary" fullWidth sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2 }}>
                            認証
                        </Button>
                    </Box>
                ) : (
                    <>
                        <Typography variant="h4" component="h1" gutterBottom>アカウント登録</Typography>
                        <Typography variant="h6">{companyData?.name}</Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                label="メールアドレス"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                {...register("email", {
                                    required: "メールアドレスは必須です",
                                    pattern: /^\S+@\S+\.\S+$/
                                })}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                            <TextField
                                label="パスワード"
                                variant="outlined"
                                type="password"
                                fullWidth
                                margin="normal"
                                {...register("password", {
                                    required: "パスワードは必須です",
                                    minLength: { value: 6, message: "パスワードは6文字以上である必要があります" }
                                })}
                                error={!!errors.password}
                                helperText={errors.password?.message}
                            />
                            <TextField
                                label="苗字"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                {...register("lastName", { required: "苗字は必須です" })}
                                error={!!errors.lastName}
                                helperText={errors.lastName?.message}
                            />
                            <TextField
                                label="名前"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                {...register("firstName", { required: "名前は必須です" })}
                                error={!!errors.firstName}
                                helperText={errors.firstName?.message}
                            />
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                // @ts-ignore 一時的に無視
                                adapterLocale={ja}
                            >
                                <FormControl component="fieldset" margin="normal" fullWidth>
                                    <Controller
                                        name="birthDate"
                                        control={control}
                                        rules={{ required: "生年月日は必須です" }}
                                        render={({ field }) => (
                                            <DatePicker
                                                views={['year', 'month', 'day']}
                                                openTo="year"
                                                format="YYYY年MM月DD日"
                                                label="生年月日"
                                                value={field.value}
                                                onChange={field.onChange}
                                                // @ts-ignore 一時的に無視
                                                renderInput={(params) => (
                                                    <TextField {...params} error={!!errors.birthDate} helperText={errors.birthDate ? '生年月日を入力してください' : null} fullWidth margin="normal" />
                                                )}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </LocalizationProvider>

                            <FormControl component="fieldset" margin="normal">
                                <FormLabel component="legend">性別</FormLabel>
                                <Controller
                                    name="gender"
                                    control={control}
                                    rules={{ required: "性別を選択してください" }}
                                    render={({ field }) => (
                                        <RadioGroup {...field} row>
                                            <FormControlLabel value="male" control={<Radio />} label="男性" />
                                            <FormControlLabel value="female" control={<Radio />} label="女性" />
                                            <FormControlLabel value="other" control={<Radio />} label="その他" />
                                        </RadioGroup>
                                    )}
                                />
                                {errors.gender && <Typography color="error">{errors.gender.message}</Typography>}
                            </FormControl>

                            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2 }}>登録</Button>
                        </form>
                    </>
                )}
            </ResponsiveContainer>
            <Footer />
        </>
    );
};

export default CompanySignupForm;
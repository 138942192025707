// service/firebase/authService.ts
import {
    applyActionCode,
    createUserWithEmailAndPassword,
    confirmPasswordReset,
    OAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    sendPasswordResetEmail,
    verifyPasswordResetCode,
} from "firebase/auth";
import { auth } from '../../firebase';
import { getFirebaseErrorMessage } from '../../utils/firebaseErrors';

export const loginWithEmail = async (email: string, password: string) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        console.log('User logged in:', userCredential.user?.email);
        return userCredential;
    } catch (error: any) {
        console.log('Error during email login:', error);  // Add error logging
        throw new Error(getFirebaseErrorMessage({
            defaultErrorMessage: 'メールアドレスまたはパスワードが正しくありません。',
            errorCode: error.code,
        }));
    }
};

export const signUpWithEmail = async (email: string, password: string) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        return userCredential;
    } catch (error: any) {
        throw new Error(getFirebaseErrorMessage({
            defaultErrorMessage: 'アカウントの作成に失敗しました。もう一度試してください。',
            errorCode: error.code,
        }));
    }
};

export const signInWithLine = async () => {
    const provider = new OAuthProvider('oidc.line');
    try {
        console.log('Attempting to sign in with LINE');
        return await signInWithPopup(auth, provider);
    } catch (err) {
        console.log('Error during LINE authentication:', err);  // Add error logging
        throw new Error('LINEでの認証に失敗しました');
    }
};

/**
 * Send password reset email to the provided address.
 * @param email User's email address
 * @returns Promise<void>
 */
export const sendPasswordReset = (email: string): Promise<void> => {
    return sendPasswordResetEmail(auth, email);
};

// パスワードリセットコードを確認
export const verifyResetPasswordCode = async (actionCode: string) => {
    return await verifyPasswordResetCode(auth, actionCode);
};

// パスワードのリセットを確定
export const resetPassword = async (actionCode: string, newPassword: string) => {
    return await confirmPasswordReset(auth, actionCode, newPassword);
};

// メールアドレスの確認
export const verifyEmailActionCode = async (actionCode: string) => {
    return await applyActionCode(auth, actionCode);
};